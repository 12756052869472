import { useRoutes } from "react-router-dom";
import Layout from "../layout";
import Services from "../pages/Services";
import AddPeople from "../pages/AddPeople";
import ViewPeople from "../pages/ViewPeople";
import LoginPage from "../pages/Login";
import Home from "../pages/Home";
import Reports from "../pages/Reports";
import PrintView from "../components/PrintView";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LoginPage />,
    },
    {
      path: "",
      element: <Layout />,
      children: [
        {
          path: "addPeople",
          element: <AddPeople />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "reports",
          element: <Reports />,
        },
        {
          path: "Services",
          element: <Services />,
        },
        {
          path: "viewPeople",
          element: <ViewPeople />,
        },
      ],
    },
    {
      path: "print-view", // Nested route for print view
      element: <PrintView />,
    },
  ]);
}
