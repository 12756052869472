import React from "react";
import { useLocation } from "react-router-dom";
import IDCard from "./IDCard";

const PrintView = () => {
  const location = useLocation();
  const { selectedUsers } = location.state || { selectedUsers: [] }; // Get selected users from state

  return (
    <div>
      <div className="py-0">
        <div className="grid grid-cols-1 gap-0">
          {selectedUsers.map((user, index) => (
            <div className="flex items-between justify-between">
              <IDCard
                key={index}
                id={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
                cell={user.cell}
                bornOn={user.bornOn}
                streetAddress={user.streetAddress}
                city={user.city}
                image={user.image}
              />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={() => window.print()} // Trigger browser print functionality
        className="bg-green-500 text-white py-2 px-4 rounded"
      >
        Print Cards
      </button>
    </div>
  );
};

export default PrintView;
