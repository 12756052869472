const config = {
  //ipAddress: "http://localhost:4000",
  ipAddress: "https://backend.appmensa.com",
  contactDetails: {
    logo: "https://tailwindui.com/img/logos/mark.svg?color=yellow&shade=500",
    anaweenCoEmail: "Info@anaween.co",
    anaweenDxbEmail: "info@anaweendxb.com",
    uaePhone: "+971589789451",
    egyptPhone: "+201012995408",
    uaeAddress: "Emaar square building 6, downtown Dubai. 7th floor.",
    uaeLocation: "https://maps.app.goo.gl/cZuNfumnti1Jdh849",
    egyptAddress:
      "Trivium Square, Building 329 & 330 1st Floor, Office 139 N90 Street; Fifth Settlement",
    egyptLocation: "https://maps.app.goo.gl/yUchu8kbHbUVBi7t8",
  },
};

export default config;
