// import React, { useEffect, useRef } from "react";
// import { useTranslation } from "react-i18next";
// import config from "../config";
// import JsBarcode from "jsbarcode";
// import { isValid, format } from "date-fns";

// function IDCard(props) {
//   const { id, firstName, lastName, cell, bornOn, streetAddress, city, image } = props;
//   const realBornOn = isValid(new Date(bornOn)) ? format(new Date(bornOn), 'dd-MM-yyyy') : "Data non valida";
//   const barcodeRef = useRef(null);
//   const { t } = useTranslation();

//   useEffect(() => {
//     if (barcodeRef.current) {
//       JsBarcode(barcodeRef.current, id, {
//         width:6,
//         height: 90,
//         fontSize: 18,
//       });
//     }
//   }, [id]);

//   return (
//     <div className="card-container">
//       <div className="card bg-stone-50 shadow-lg rounded-lg">
//         <div className="card-header bg-gray-200 flex items-center p-2">
//           <img
//             className="logo w-14 h-14 object-cover rounded-full mr-4"
//             src="logo.png"
//             alt="Logo"
//           />
//           <div>
//             <h1 className="text-black text-2xl font-bold">Opera Sant'Antonio</h1>
//             <h2 className="text-black text-sm">PER I POVERI-ODV</h2>
//             <h3 className="text-black text-xs">Via della Fiera 5, Rimini. Tel: 0541783169.</h3>
//           </div>
//         </div>
//         <div className="card-body flex p-2 gap-8">
//           <div className="user-details flex-1 gap-2">
//             <div>
//               <h2 className="text-sm font-semibold">{firstName || ""} {lastName || ""}</h2>
//               <h1 className="text-sm font-bold">{realBornOn}</h1>
//             </div>
//             <img ref={barcodeRef} className="w-full mb-3" alt="Barcode" />
//           </div>
//           <img
//             className="user-image w-20 h-20 object-cover rounded-full border border-gray-800 ml-4 mb-4"
//             src={image ? `${config.ipAddress}/file/${image}` : "user.png"}
//             alt="User"
//           />
//         </div>
//         <div className="card-footer bg-gray-200 text-sm text-center whitespace-nowrap pb-1">
//           {t("This card can be withdrawn at any time.")}
//         </div>
//       </div>
//     </div>
//   );
// }
// export default React.memo(IDCard);

import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import JsBarcode from "jsbarcode";
import { isValid, format } from "date-fns";

function IDCard(props) {
  const { id, firstName, lastName, cell, bornOn, streetAddress, city, image } =
    props;
  const realBornOn = isValid(new Date(bornOn))
    ? format(new Date(bornOn), "dd-MM-yyyy")
    : "Data non valida";
  const barcodeRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, id, {
        width: 2, // Adjust width for better consistency
        height: 60, // Adjust height for better consistency
        fontSize: 18, // Uniform font size
        displayValue: true, // Hide the value to maintain uniformity
      });
    }
  }, [id]);

  return (
    <div className="card-container">
      <style>
        {`
          @media print {
            .card-header,
            .card-footer {
              background-color: ##F0F0F0; /* Match your bg-gray-200 color */
              -webkit-print-color-adjust: exact; /* Ensure colors are printed exactly */
              color: #000; /* Optional: set text color for printing */
            }
          
            /* Ensure that shadows are not printed */
            .shadow-lg {
              box-shadow: none;
            }
          
            /* Hide any elements that are not needed in print */
            button {
              display: none;
            }
          }
        `}
      </style>
      <div className="card bg-stone-50 shadow-lg rounded-lg overflow-hidden">
        <div className="card-header bg-gray-200 flex items-center p-4">
          <img
            className="logo w-14 h-14 object-cover rounded-full mr-4"
            src="logo.png"
            alt="Logo"
          />
          <div>
            <h1 className="text-black text-2xl font-bold">
              Opera Sant'Antonio
            </h1>
            <h2 className="text-black text-sm">PER I POVERI-ODV</h2>
            <h3 className="text-black text-xs">
              Via della Fiera 5, Rimini. Tel: 0541783169.
            </h3>
          </div>
        </div>
        <div className="card-body flex p-4">
          <div className="user-details flex-1 flex flex-col items-start">
            <h2 className="text-sm font-semibold">{`${firstName || ""} ${lastName || ""}`}</h2>
            <h1 className="text-sm font-bold mb-2">{realBornOn}</h1>
            <img
              ref={barcodeRef}
              className=""
              alt="Barcode"
              style={{ width: "40%", height: "50px" }} // Ensures barcode has consistent height
            />
          </div>
          <img
            className="user-image w-20 h-20 object-cover rounded-full border border-gray-800"
            src={image ? `${config.ipAddress}/file/${image}` : "user.png"}
            alt="User"
          />
        </div>
        <div className="card-footer bg-gray-200 text-sm text-center whitespace-nowrap pb-1">
          {t("This card can be withdrawn at any time.")}
        </div>
      </div>
    </div>
  );
}

export default React.memo(IDCard);
