import { EyeIcon, ScaleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";
import Datepicker from "react-tailwindcss-datepicker";
import { subDays } from "date-fns";
import Chart from "react-apexcharts";
import DataTable from "../components/DataTable";
import Paginator from "../components/paginator";
import Modal from "../components/Modal";
import UserData from "../components/UserData";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import Barchart from "../components/Barchart";
import { nationalities } from "../utils/nationalities";

export default function Reports() {
  const { t, i18n } = useTranslation();
  const [stats, setStats] = React.useState({});
  const [barchartStats, setBarchartStats] = React.useState({});

  const [counts, setCounts] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [tableBodyList, setTableBodyList] = useState([]);
  const originalDataRef = useRef([]); // Store the original data without using state

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [peopleData, setPeopleData] = useState(false);

  const [id, setId] = useState();
  const [name, setName] = useState();
  const [child, setChild] = useState();
  const [service, setService] = useState("CAFETERIA");

  const [malePercentage, setMalePercentage] = useState(0);
  const [femalePercentage, setfemalePercentage] = useState(0);
  const [AgeGroupsPercentage, setAgeGroupsPercentage] = useState({
    "19-30": 6,
    "31-40": 0,
    "41-50": 0,
    "51-60": 0,
    "61+": 0,
  });

  const [nationalityStats, setnationalityStats] = useState({
    IT: 0,
    UA: 0,
    MA: 0,
    RO: 0,
    RU: 0,
    MD: 0,
    TN: 0,
    PE: 0,
    SN: 0,
    AL: 0,
    other: 0,
  });

  const [value, setValue] = useState({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const cards = [
    { id: "cafeteria", nameKey: "Cafeteria", icon: ScaleIcon },
    { id: "takeawayPackage", nameKey: "Takeaway Package", icon: ScaleIcon },
    { id: "showers", nameKey: "Shower", icon: ScaleIcon },
    { id: "covers", nameKey: "Covers", icon: ScaleIcon },
    { id: "medicines", nameKey: "Medicines", icon: ScaleIcon },
  ];

  console.log("stats", cards);
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 1],
      curve: "smooth",
    },
    fill: {
      colors: ["#1A73E8", "#4CAF50", "#9C27B0", " #FF5722", "#673AB7"],
    },
    legend: {
      show: false,
    },
    colors: ["#1C64F2", "#8BC34A", "#E91E63", "#FF9800", "#3F51B5"],
    labels: stats.cafeteria?.map((x) => x.time) || [],
    xaxis: { type: "datetime" },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const [tableHeaders, setTableHeaders] = useState([
    { id: "id", label: t("ID") },
    {
      id: "First Name",
      label: t("First Name"),
      component: (data) => <>{data.firstName || ""}</>,
    },
    {
      id: "Last Name",
      label: t("Last Name"),
      component: (data) => <>{data.lastName || ""}</>,
    },
    {
      id: "gender",
      label: t("Gender"),
      component: (data) => <>{t(data.gender) || ""} </>,
    },
    {
      id: "livewith",
      label: t("Born in"),
      component: (data) => <>{data.livewith || ""} </>,
    },
    {
      id: "city",
      label: t("City"),
      component: (data) => <>{t(data.city) || ""} </>,
    },
    {
      id: "emailAddress",
      label: t("Email"),
      component: (data) => <>{data.emailAddress || ""} </>,
    },
    {
      id: "cell",
      label: t("Mobile phone"),
      component: (data) => <>{data.cell || ""}</>,
    },

    {
      id: "Image",
      label: t("Photo"),
      component: (data, setData) => (
        <img
          className="w-16 h-16 rounded-full"
          // src={data.Image}
          src={
            data.image ? `${config.ipAddress}/file/${data.image}` : "user.png"
          }
          alt="profile"
        />
      ),
    },
    {
      id: "actions",
      label: "",
      component: (data, setData) => (
        <div className="flex space-x-3 !text-right">
          <button
            className=" no-focus"
            title="User Data"
            onClick={(e) => setPeopleData(data)}
          >
            <EyeIcon className="w-5 h-5" />
          </button>
        </div>
      ),
    },
  ]);

  //   function calculateAgeStatistics(data) {
  //     const currentYear = new Date().getFullYear();

  //     const ageGroups = {
  //         "19-30": 0,
  //         "31-40": 0,
  //         "41-50": 0,
  //         "51-60": 0,
  //         "61+": 0
  //     };

  //     data.forEach(person => {
  //         const birthYear = new Date(person.bornOn).getFullYear();
  //         const age = currentYear - birthYear;

  //         if (age >= 19 && age <= 30) {
  //             ageGroups["19-30"]++;
  //         } else if (age >= 31 && age <= 40) {
  //             ageGroups["31-40"]++;
  //         } else if (age >= 41 && age <= 50) {
  //             ageGroups["41-50"]++;
  //         } else if (age >= 51 && age <= 60) {
  //             ageGroups["51-60"]++;
  //         } else if (age >= 61) {
  //             ageGroups["61+"]++;
  //         }
  //     });

  //     const total = data.length;

  //     return {
  //         "19-30": Number(((ageGroups["19-30"] / total) * 100).toFixed(2)),
  //         "31-40": Number(((ageGroups["31-40"] / total) * 100).toFixed(2)),
  //         "41-50": Number(((ageGroups["41-50"] / total) * 100).toFixed(2)),
  //         "51-60": Number(((ageGroups["51-60"] / total) * 100).toFixed(2)),
  //         "61+": Number(((ageGroups["61+"] / total) * 100).toFixed(2))
  //     };
  // }

  const calculateStatisticsFromServices = (servicesList) => {
    console.log(servicesList, "servicesList");
    // Get unique persons from services to avoid counting the same person multiple times
    // const uniquePersons = Array.from(new Set(servicesList.map(service => service.personId)))
    //   .map(personId => {
    //     return servicesList.find(service => service.personId === personId);
    //   });

    const uniquePersons = servicesList;
    // Calculate gender statistics
    const males = uniquePersons.filter(
      (service) => service.person?.gender === "Male"
    );
    console.log(males, "male");
    const females = uniquePersons.filter(
      (service) => service.person?.gender === "Female"
    );
    const totalPeople = uniquePersons.length;

    const newMalePercentage = totalPeople
      ? (males.length / totalPeople) * 100
      : 0;
    const newFemalePercentage = totalPeople
      ? (females.length / totalPeople) * 100
      : 0;

    // Calculate age statistics
    const currentYear = new Date().getFullYear();
    const ageGroups = {
      "19-30": 0,
      "31-40": 0,
      "41-50": 0,
      "51-60": 0,
      "61+": 0,
    };

    uniquePersons.forEach((service) => {
      if (service.person?.bornOn) {
        const birthYear = new Date(service.person.bornOn).getFullYear();
        const age = currentYear - birthYear;

        if (age >= 19 && age <= 30) ageGroups["19-30"]++;
        else if (age >= 31 && age <= 40) ageGroups["31-40"]++;
        else if (age >= 41 && age <= 50) ageGroups["41-50"]++;
        else if (age >= 51 && age <= 60) ageGroups["51-60"]++;
        else if (age >= 61) ageGroups["61+"]++;
      }
    });

    const newAgeGroupsPercentage = Object.keys(ageGroups).reduce(
      (acc, group) => {
        acc[group] = totalPeople
          ? Number((ageGroups[group] / totalPeople) * 100).toFixed(2)
          : 0;
        return acc;
      },
      {}
    );

    // Calculate nationality statistics
    const nationalities = {
      IT: 0,
      UA: 0,
      MA: 0,
      RO: 0,
      RU: 0,
      MD: 0,
      TN: 0,
      PE: 0,
      SN: 0,
      AL: 0,
      other: 0,
    };

    uniquePersons.forEach((service) => {
      const nationality = service.person?.nationality;
      if (nationality) {
        if (nationality in nationalities) {
          nationalities[nationality]++;
        } else {
          nationalities.other++;
        }
      }
    });

    const newNationalityStats = Object.keys(nationalities).reduce(
      (acc, nat) => {
        acc[nat] = totalPeople
          ? Number((nationalities[nat] / totalPeople) * 100).toFixed(2)
          : 0;
        return acc;
      },
      {}
    );

    return {
      malePercentage: newMalePercentage,
      femalePercentage: newFemalePercentage,
      ageGroupsPercentage: newAgeGroupsPercentage,
      nationalityStats: newNationalityStats,
    };
  };

  // function calculateNationalityPercentage(people) {
  //   let arr=['IT','UA','MA','RO','RU','MD','TN','PE','SN','AL']
  //   let targetCountries = {
  //     "IT": "Italy",
  //     "UA": "Ukraine",
  //     "MA": "Morocco",
  //     "RO": "Romania",
  //     "RU": "Russia",
  //     "MD": "Moldova",
  //     "TN": "Tunisia",
  //     "PE": "Peru",
  //     "SN": "Senegal",
  //     "AL": "Albania",
  // };
  // let obj={

  // }

  // arr?.map(nt=>{
  //   let vf=people.filter(el=>el?.nationality==nt);
  //   obj[nt]=((vf?.length/people?.length)*100).toFixed(2)
  // }
  // )
  // let ot=people?.filter(el=>!arr?.includes(el?.nationality))
  // obj['other']=((ot?.length/people?.length)*100).toFixed(2)
  // return obj;
  // }

  // const getPersons=()=>{
  //   debugger
  //   axios
  //   .get(`${config.ipAddress}/person`,{
  //     params: {
  //       page:0,
  //       limit:10000,
  //       search: ''
  //     },
  //   }) // Pass searchQuery to API call
  //   .then((res) => {
  //     console.log("new data", res.data.list);
  //     debugger
  //    let males= res.data.list?.filter(el=>el?.gender=='Male')
  //    let feMales= res.data.list?.filter(el=>el?.gender=='Female')
  //    let malesPercentage=(males?.length/res?.data.list?.length)*100;
  //    let femalePercentage=(feMales?.length/res?.data.list?.length)*100;
  //    setMalePercentage(malesPercentage)
  //    setfemalePercentage(femalePercentage)
  //    console.log('malesPercentage',malesPercentage)
  //    console.log('femalePercentage',femalePercentage)
  //    console.log('calculateAgeStatistics(res.data.list)',calculateAgeStatistics(res.data.list))
  //    setAgeGroupsPercentage(calculateAgeStatistics(res.data.list))
  //   setnationalityStats(calculateNationalityPercentage(res.data.list))
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     setLoading(false);
  //   });
  // }

  const getServices = () => {
    axios
      .get(`${config.ipAddress}/service`, {
        params: {
          ...(id && { id }),
          ...(service && { service }),
          ...(child && { child }),
          ...(name && { name }),
          ...(value.startDate && { startDate: value.startDate }),
          ...(value.endDate && { endDate: value.endDate }),
          page,
          limit,
        },
      }) // Pass searchQuery to API call
      .then((res) => {
        setTableBodyList(res.data.list);
        setCount(res.data.count);

        // Store the original data if it's the first fetch
        if (!originalDataRef.current.length) {
          originalDataRef.current = res.data.list;
        }

        // Calculate all statistics from service data
        const stats = calculateStatisticsFromServices(res.data.list);

        // Update all the statistics states
        setMalePercentage(stats.malePercentage);
        setfemalePercentage(stats.femalePercentage);
        setAgeGroupsPercentage(stats.ageGroupsPercentage);
        setnationalityStats(stats.nationalityStats);

        console.log(
          "Percentages",
          stats.malePercentage,
          stats.femalePercentage,
          stats.ageGroupsPercentage,
          stats.nationalityStats
        );

        console.log(res.data.list, "Data at Line 277");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log(value, "value");
    axios
      .get(`${config.ipAddress}/service/count`, {
        params: { startDate: value.startDate, endDate: value.endDate },
      })
      .then((res) => {
        console.log(res, "My data");
        setCounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${config.ipAddress}/service/stats`, {
        params: { startDate: value.startDate, endDate: value.endDate },
      })
      .then((res) => {
        console.log("Stats of Given time", res.data);
        setStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${config.ipAddress}/service/barchart`, {
        params: { startDate: value.startDate, endDate: value.endDate },
      })
      .then((res) => {
        setBarchartStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(true);

    getServices();
  }, [value]);

  // useEffect(()=>{
  //   getPersons()
  // },[])

  const handleDownload = async () => {
    try {
      // Make a GET request to download the file
      const response = await axios.get(`${config.ipAddress}/service/export`, {
        responseType: "blob", // Set responseType to 'blob' to receive binary data
      });

      // Create a blob URL and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "generated_file.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleClearSearch = () => {
    // Reset filter states
    setId("");
    setService("");
    setChild("");
    setName("");
    setValue({ startDate: "", endDate: "" });
    setPage(1);

    // Reset table data to the original unfiltered data
    setTableBodyList(originalDataRef.current);
  };

  const genderChartOptions = {
    series: [
      {
        name: "Gender Distribution",
        data: [malePercentage, femalePercentage],
      },
    ],
    options: {
      chart: {
        type: "pie",
      },
      labels: ["Male", "Female"],
      colors: ["#1C64F2", "#FF5733"],
      tooltip: {
        y: {
          formatter: (val) => `${val.toFixed(0)}%`,
        },
      },
      legend: {
        position: "bottom",
      },
    },
  };

  const series = [
    {
      name: t("Cafeteria"),
      data: stats.cafeteria?.map((x) => x.count.toFixed(2) || 0) || [],
    },
    {
      name: t("Takeaway Package"),
      data: stats.takeawayPackage?.map((x) => x.count.toFixed(2) || 0) || [],
    },
    {
      name: t("Showers"),
      data: stats.showers?.map((x) => x.count.toFixed(2) || 0) || [],
    },
    {
      name: t("Covers"),
      data: stats.covers?.map((x) => x.count.toFixed(2) || 0) || [],
    },
    {
      name: t("Medicines"),
      data: stats.medicines?.map((x) => x.count.toFixed(2) || 0) || [],
    },
  ];

  return (
    <>
      <div className="mx-auto w-[77%] px-4 sm:px-6 lg:px-8 mt-8">
        <div className="inline-flex w-[100%] justify-between ">
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            {t("Overview")}
          </h2>
          <div className="w-1/3">
            <Datepicker value={value} onChange={handleValueChange} />
          </div>
        </div>

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}

          {cards.map((card) => (
            <div
              key={card.id}
              className="overflow-hidden rounded-lg bg-white shadow"
            >
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <card.icon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-medium text-gray-500">
                        {t(card.nameKey)}
                      </dt>
                      <dd>
                        <div className="text-lg font-medium text-gray-900">
                          {counts[card.id] || 0}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal isModalOpen={!!peopleData} setModalOpen={setPeopleData}>
          <div className="bg-white text-left text-black w-[600px] md:w-[700px] rounded-lg my-8">
            <div className="border-b flex justify-between items-center px-6 py-4">
              <h4 className="text-base text-gray-900 font-semibold">
                {t("User Data")}
              </h4>
              <XMarkIcon
                onClick={() => setPeopleData(false)}
                className="w-5 h-5 text-gray-600 cursor-pointer"
              />
            </div>

            <UserData {...peopleData} />
          </div>
        </Modal>

        <div className="p-6 shadow-md  rounded-lg bg-white mt-4">
          <Chart options={options} series={series} type="area" height={200} />
        </div>

        <h2 className="text-lg font-medium leading-6 text-gray-900 px-7 mt-8 mb-4">
          {t("Filters")}
        </h2>
        <div className="my-2">
          <div className="px-8 grid  gap-4 md:grid-cols-5">
            <div className="">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("ID")}
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="id"
                  id="id"
                  value={id}
                  onChange={(e) => setId(e.target.value)}
                  autoComplete="given-name"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Name")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="given-name"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Child")}
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="Child"
                  id="Child"
                  value={child}
                  onChange={(e) => setChild(e.target.value)}
                  autoComplete="given-name"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Service")}
              </label>
              <div className="mt-2">
                <select
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                  autoComplete="Marital status"
                  className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                >
                  <option value={"CAFETERIA"}>{t("Cafeteria")}</option>
                  <option value={"TAKEAWAY_PACKAGE"}>
                    {t("Takeaway Package")}
                  </option>
                  <option value={"SHOWERS"}>{t("Shower")}</option>
                  <option value={"COVERS"}>{t("Covers")}</option>
                  <option value={"MEDICINES"}>{t("Medicines")}</option>
                </select>
              </div>
            </div>

            <div className="inline-flex">
              <div className="mt-8">
                <button
                  type="button"
                  onClick={getServices}
                  class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    class="w-4 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                  <span class="sr-only">Search</span>
                </button>
              </div>
              <div className="mt-8">
                <button
                  onClick={handleDownload}
                  type="submit"
                  class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <ArrowUpTrayIcon className="w-4 h-4" />
                  <span class="sr-only">Export</span>
                </button>
              </div>

              <div className="mt-8">
                <button
                  onClick={handleClearSearch}
                  type="button"
                  className="p-2 ms-2 text-sm font-medium text-white bg-red-600 rounded-lg border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  {t("Clear")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          isLoading={loading}
          tableHeadersData={tableHeaders}
          setTableHeadersData={setTableHeaders}
          tableBodyData={tableBodyList || []}
          renderPaginator={() => (
            <>
              <Paginator
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                total={count}
              />
            </>
          )}
        />

        <div className="p-6 shadow-md  rounded-lg bg-white mt-4">
          <Barchart barchartStats={barchartStats} />
        </div>
      </div>
      <div className="mx-auto w-[77%] mt-4">
        <div className="pieChartMain">
          <h1 className="chartHeading">{t("Gender")}</h1>
          <Chart
            options={{
              labels: [t("Male"), t("Female")],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={[malePercentage, femalePercentage]}
            type="pie"
            width="380"
          />
        </div>

        <div className="pieChartMain">
          <h1 className="chartHeading">{t("Age Groups")}</h1>
          <Chart
            options={{
              labels: [
                "19 to 30",
                "31 to 40",
                "41 to 50",
                "51 to 60",
                "over 61",
              ],
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={[
              AgeGroupsPercentage["19-30"],
              AgeGroupsPercentage["31-40"],
              AgeGroupsPercentage["41-50"],
              AgeGroupsPercentage["41-50"],
              AgeGroupsPercentage["61+"],
            ]}
            type="pie"
            width="380"
          />
        </div>

        <div className="pieChartMain">
          <h1 className="chartHeading">{t("Age Groups")}</h1>
          <Chart
            options={{
              chart: {
                type: "bar",
              },
              xaxis: {
                categories: [
                  "19 to 30",
                  "31 to 40",
                  "41 to 50",
                  "51 to 60",
                  "over 61",
                ],
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
              },
              legend: {
                position: "top",
              },
            }}
            series={[
              {
                name: "Percentage",
                data: [
                  AgeGroupsPercentage["19-30"],
                  AgeGroupsPercentage["31-40"],
                  AgeGroupsPercentage["41-50"],
                  AgeGroupsPercentage["41-50"],
                  AgeGroupsPercentage["61+"],
                ], // Percentages of males and females
              },
            ]}
            type="bar"
            width="500"
          />
        </div>

        <div className="pieChartMain">
          <h1 className="chartHeading">{t("Nationality")}</h1>
          <Chart
            options={{
              chart: {
                type: "pie",
              },
              xaxis: {
                categories: [
                  "Italy",
                  "Ukraine",
                  "Morocco",
                  "Romania",
                  "Russia",
                  "Moldova",
                  "Tunisia",
                  "Peru",
                  "Senegal",
                  "Albania",
                  "Other",
                ],
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
              },
              legend: {
                position: "top",
              },
            }}
            series={[
              {
                name: "Percentage",
                data: [
                  nationalityStats["IT"],
                  nationalityStats["UA"],
                  nationalityStats["MA"],
                  nationalityStats["RO"],
                  nationalityStats["RU"],
                  nationalityStats["MD"],
                  nationalityStats["TN"],
                  nationalityStats["PE"],
                  nationalityStats["SN"],
                  nationalityStats["AL"],
                  nationalityStats["other"],
                ], // Percentages of males and females
              },
            ]}
            type="bar"
            width="700"
          />
        </div>
      </div>
    </>
  );
}
