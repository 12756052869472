export const nationalities = [
  {
    "num_code": "4",
    "alpha_2_code": "AF",
    "alpha_3_code": "AFG",
    "en_short_name": "Afghanistan",
    "nationality": "Afghan",
    "it_short_name": "Afganistan",
    "it_nationality": "afgano",
},
{
    "num_code": "248",
    "alpha_2_code": "AX",
    "alpha_3_code": "ALA",
    "en_short_name": "Åland Islands",
    "nationality": "Åland Island",
    "it_short_name": "Isole Åland",
    "it_nationality": "Isola di Åland",
},
{
    "num_code": "8",
    "alpha_2_code": "AL",
    "alpha_3_code": "ALB",
    "en_short_name": "Albania",
    "nationality": "Albanian",
    "it_short_name": "Albania",
    "it_nationality": "albanese",
},
{
    "num_code": "12",
    "alpha_2_code": "DZ",
    "alpha_3_code": "DZA",
    "en_short_name": "Algeria",
    "nationality": "Algerian",
    "it_short_name": "Algeria",
    "it_nationality": "algerino",
},
{
    "num_code": "16",
    "alpha_2_code": "AS",
    "alpha_3_code": "ASM",
    "en_short_name": "American Samoa",
    "nationality": "American Samoan",
    "it_short_name": "Samoa americane",
    "it_nationality": "Samoano americano",
},
{
    "num_code": "20",
    "alpha_2_code": "AD",
    "alpha_3_code": "AND",
    "en_short_name": "Andorra",
    "nationality": "Andorran",
    "it_short_name": "Andorra",
    "it_nationality": "Andorrano",
},
{
    "num_code": "24",
    "alpha_2_code": "AO",
    "alpha_3_code": "AGO",
    "en_short_name": "Angola",
    "nationality": "Angolan",
    "it_short_name": "Angola",
    "it_nationality": "angolano",
},
{
    "num_code": "660",
    "alpha_2_code": "AI",
    "alpha_3_code": "AIA",
    "en_short_name": "Anguilla",
    "nationality": "Anguillan",
    "it_short_name": "Anguilla",
    "it_nationality": "anguillano",
},
{
    "num_code": "10",
    "alpha_2_code": "AQ",
    "alpha_3_code": "ATA",
    "en_short_name": "Antarctica",
    "nationality": "Antarctic",
    "it_short_name": "Antartide",
    "it_nationality": "antartico",
},
{
    "num_code": "28",
    "alpha_2_code": "AG",
    "alpha_3_code": "ATG",
    "en_short_name": "Antigua and Barbuda",
    "nationality": "Antiguan or Barbudan",
    "it_short_name": "Antigua e Barbuda",
    "it_nationality": "Antigua o Barbudan",
},
{
    "num_code": "32",
    "alpha_2_code": "AR",
    "alpha_3_code": "ARG",
    "en_short_name": "Argentina",
    "nationality": "Argentine",
    "it_short_name": "Argentina",
    "it_nationality": "argentino",
},
{
    "num_code": "51",
    "alpha_2_code": "AM",
    "alpha_3_code": "ARM",
    "en_short_name": "Armenia",
    "nationality": "Armenian",
    "it_short_name": "Armenia",
    "it_nationality": "armeno",
},
{
    "num_code": "533",
    "alpha_2_code": "AW",
    "alpha_3_code": "ABW",
    "en_short_name": "Aruba",
    "nationality": "Aruban",
    "it_short_name": "Aruba",
    "it_nationality": "Arubano",
},
{
    "num_code": "36",
    "alpha_2_code": "AU",
    "alpha_3_code": "AUS",
    "en_short_name": "Australia",
    "nationality": "Australian",
    "it_short_name": "Australia",
    "it_nationality": "australiano",
},
{
    "num_code": "40",
    "alpha_2_code": "AT",
    "alpha_3_code": "AUT",
    "en_short_name": "Austria",
    "nationality": "Austrian",
    "it_short_name": "Austria",
    "it_nationality": "austriaco",
},
{
    "num_code": "31",
    "alpha_2_code": "AZ",
    "alpha_3_code": "AZE",
    "en_short_name": "Azerbaijan",
    "nationality": "Azerbaijani, Azeri",
    "it_short_name": "Azerbaigian",
    "it_nationality": "Azero, Azero",
},
{
    "num_code": "44",
    "alpha_2_code": "BS",
    "alpha_3_code": "BHS",
    "en_short_name": "Bahamas",
    "nationality": "Bahamian",
    "it_short_name": "Bahamas",
    "it_nationality": "Bahamas",
},
{
    "num_code": "48",
    "alpha_2_code": "BH",
    "alpha_3_code": "BHR",
    "en_short_name": "Bahrain",
    "nationality": "Bahraini",
    "it_short_name": "Bahrein",
    "it_nationality": "Bahreinita",
},
{
    "num_code": "50",
    "alpha_2_code": "BD",
    "alpha_3_code": "BGD",
    "en_short_name": "Bangladesh",
    "nationality": "Bangladeshi",
    "it_short_name": "Bangladesh",
    "it_nationality": "bengalese",
},
{
    "num_code": "52",
    "alpha_2_code": "BB",
    "alpha_3_code": "BRB",
    "en_short_name": "Barbados",
    "nationality": "Barbadian",
    "it_short_name": "Barbados",
    "it_nationality": "Barbados",
},
{
    "num_code": "112",
    "alpha_2_code": "BY",
    "alpha_3_code": "BLR",
    "en_short_name": "Belarus",
    "nationality": "Belarusian",
    "it_short_name": "Bielorussia",
    "it_nationality": "Bielorusso",
},
{
    "num_code": "56",
    "alpha_2_code": "BE",
    "alpha_3_code": "BEL",
    "en_short_name": "Belgium",
    "nationality": "Belgian",
    "it_short_name": "Belgio",
    "it_nationality": "belga",
},
{
    "num_code": "84",
    "alpha_2_code": "BZ",
    "alpha_3_code": "BLZ",
    "en_short_name": "Belize",
    "nationality": "Belizean",
    "it_short_name": "Belize",
    "it_nationality": "Beliziano",
},
{
    "num_code": "204",
    "alpha_2_code": "BJ",
    "alpha_3_code": "BEN",
    "en_short_name": "Benin",
    "nationality": "Beninese, Beninois",
    "it_short_name": "Benin",
    "it_nationality": "Beninese, Beninese",
},
{
    "num_code": "60",
    "alpha_2_code": "BM",
    "alpha_3_code": "BMU",
    "en_short_name": "Bermuda",
    "nationality": "Bermudian, Bermudan",
    "it_short_name": "Bermuda",
    "it_nationality": "Bermuda, Bermuda",
},
{
    "num_code": "64",
    "alpha_2_code": "BT",
    "alpha_3_code": "BTN",
    "en_short_name": "Bhutan",
    "nationality": "Bhutanese",
    "it_short_name": "Il Bhutan",
    "it_nationality": "Bhutanese",
},
{
    "num_code": "68",
    "alpha_2_code": "BO",
    "alpha_3_code": "BOL",
    "en_short_name": "Bolivia (Plurinational State of)",
    "nationality": "Bolivian",
    "it_short_name": "Bolivia (Stato Plurinazionale di)",
    "it_nationality": "boliviano",
},
{
    "num_code": "535",
    "alpha_2_code": "BQ",
    "alpha_3_code": "BES",
    "en_short_name": "Bonaire, Sint Eustatius and Saba",
    "nationality": "Bonaire",
    "it_short_name": "Bonaire, Sint Eustatius e Saba",
    "it_nationality": "Bonaire",
},
{
    "num_code": "70",
    "alpha_2_code": "BA",
    "alpha_3_code": "BIH",
    "en_short_name": "Bosnia and Herzegovina",
    "nationality": "Bosnian or Herzegovinian",
    "it_short_name": "Bosnia ed Erzegovina",
    "it_nationality": "Bosniaco o Erzegovina",
},
{
    "num_code": "72",
    "alpha_2_code": "BW",
    "alpha_3_code": "BWA",
    "en_short_name": "Botswana",
    "nationality": "Motswana, Botswanan",
    "it_short_name": "Botswana",
    "it_nationality": "Motswana, Botswana",
},
{
    "num_code": "74",
    "alpha_2_code": "BV",
    "alpha_3_code": "BVT",
    "en_short_name": "Bouvet Island",
    "nationality": "Bouvet Island",
    "it_short_name": "Isola Bouvet",
    "it_nationality": "Isola Bouvet",
},
{
    "num_code": "76",
    "alpha_2_code": "BR",
    "alpha_3_code": "BRA",
    "en_short_name": "Brazil",
    "nationality": "Brazilian",
    "it_short_name": "Brasile",
    "it_nationality": "brasiliano",
},
{
    "num_code": "86",
    "alpha_2_code": "IO",
    "alpha_3_code": "IOT",
    "en_short_name": "British Indian Ocean Territory",
    "nationality": "BIOT",
    "it_short_name": "Territorio britannico dell'Oceano Indiano",
    "it_nationality": "BIOT",
},
{
    "num_code": "96",
    "alpha_2_code": "BN",
    "alpha_3_code": "BRN",
    "en_short_name": "Brunei Darussalam",
    "nationality": "Bruneian",
    "it_short_name": "Brunei Darussalam",
    "it_nationality": "Brunei",
},
{
    "num_code": "100",
    "alpha_2_code": "BG",
    "alpha_3_code": "BGR",
    "en_short_name": "Bulgaria",
    "nationality": "Bulgarian",
    "it_short_name": "Bulgaria",
    "it_nationality": "bulgaro",
},
{
    "num_code": "854",
    "alpha_2_code": "BF",
    "alpha_3_code": "BFA",
    "en_short_name": "Burkina Faso",
    "nationality": "Burkinabé",
    "it_short_name": "Burkina Faso",
    "it_nationality": "Burkinabé",
},
{
    "num_code": "108",
    "alpha_2_code": "BI",
    "alpha_3_code": "BDI",
    "en_short_name": "Burundi",
    "nationality": "Burundian",
    "it_short_name": "Burundi",
    "it_nationality": "Burundi",
},
{
    "num_code": "132",
    "alpha_2_code": "CV",
    "alpha_3_code": "CPV",
    "en_short_name": "Cabo Verde",
    "nationality": "Cabo Verdean",
    "it_short_name": "Capo Verde",
    "it_nationality": "Capoverdiano",
},
{
    "num_code": "116",
    "alpha_2_code": "KH",
    "alpha_3_code": "KHM",
    "en_short_name": "Cambodia",
    "nationality": "Cambodian",
    "it_short_name": "Cambogia",
    "it_nationality": "cambogiano",
},
{
    "num_code": "120",
    "alpha_2_code": "CM",
    "alpha_3_code": "CMR",
    "en_short_name": "Cameroon",
    "nationality": "Cameroonian",
    "it_short_name": "Camerun",
    "it_nationality": "camerunense",
},
{
    "num_code": "124",
    "alpha_2_code": "CA",
    "alpha_3_code": "CAN",
    "en_short_name": "Canada",
    "nationality": "Canadian",
    "it_short_name": "Canada",
    "it_nationality": "canadese",
},
{
    "num_code": "136",
    "alpha_2_code": "KY",
    "alpha_3_code": "CYM",
    "en_short_name": "Cayman Islands",
    "nationality": "Caymanian",
    "it_short_name": "Isole Cayman",
    "it_nationality": "Caymaniano",
},
{
    "num_code": "140",
    "alpha_2_code": "CF",
    "alpha_3_code": "CAF",
    "en_short_name": "Central African Republic",
    "nationality": "Central African",
    "it_short_name": "Repubblica Centrafricana",
    "it_nationality": "Centrafricano",
},
{
    "num_code": "148",
    "alpha_2_code": "TD",
    "alpha_3_code": "TCD",
    "en_short_name": "Chad",
    "nationality": "Chadian",
    "it_short_name": "Chad",
    "it_nationality": "Ciadiano",
},
{
    "num_code": "152",
    "alpha_2_code": "CL",
    "alpha_3_code": "CHL",
    "en_short_name": "Chile",
    "nationality": "Chilean",
    "it_short_name": "Chile",
    "it_nationality": "cileno",
},
{
    "num_code": "156",
    "alpha_2_code": "CN",
    "alpha_3_code": "CHN",
    "en_short_name": "China",
    "nationality": "Chinese",
    "it_short_name": "Cina",
    "it_nationality": "cinese",
},
{
    "num_code": "162",
    "alpha_2_code": "CX",
    "alpha_3_code": "CXR",
    "en_short_name": "Christmas Island",
    "nationality": "Christmas Island",
    "it_short_name": "Isola di Natale",
    "it_nationality": "Isola di Natale",
},
{
    "num_code": "166",
    "alpha_2_code": "CC",
    "alpha_3_code": "CCK",
    "en_short_name": "Cocos (Keeling) Islands",
    "nationality": "Cocos Island",
    "it_short_name": "Isole Cocos (Keeling)",
    "it_nationality": "Isola di Coco",
},
{
    "num_code": "170",
    "alpha_2_code": "CO",
    "alpha_3_code": "COL",
    "en_short_name": "Colombia",
    "nationality": "Colombian",
    "it_short_name": "Colombia",
    "it_nationality": "Colombiano",
},
{
    "num_code": "174",
    "alpha_2_code": "KM",
    "alpha_3_code": "COM",
    "en_short_name": "Comoros",
    "nationality": "Comoran, Comorian",
    "it_short_name": "Comore",
    "it_nationality": "Comorano, Comoriano",
},
{
    "num_code": "178",
    "alpha_2_code": "CG",
    "alpha_3_code": "COG",
    "en_short_name": "Congo (Republic of the)",
    "nationality": "Congolese",
    "it_short_name": "Congo (Repubblica del)",
    "it_nationality": "Congolese",
},
{
    "num_code": "180",
    "alpha_2_code": "CD",
    "alpha_3_code": "COD",
    "en_short_name": "Congo (Democratic Republic of the)",
    "nationality": "Congolese",
    "it_short_name": "Congo (Repubblica Democratica del)",
    "it_nationality": "Congolese",
},
{
    "num_code": "184",
    "alpha_2_code": "CK",
    "alpha_3_code": "COK",
    "en_short_name": "Cook Islands",
    "nationality": "Cook Island",
    "it_short_name": "Isole Cook",
    "it_nationality": "Isola di Cook",
},
{
    "num_code": "188",
    "alpha_2_code": "CR",
    "alpha_3_code": "CRI",
    "en_short_name": "Costa Rica",
    "nationality": "Costa Rican",
    "it_short_name": "Costa Rica",
    "it_nationality": "Costaricano",
},
{
    "num_code": "384",
    "alpha_2_code": "CI",
    "alpha_3_code": "CIV",
    "en_short_name": "Côte d'Ivoire",
    "nationality": "Ivorian",
    "it_short_name": "Costa d'Avorio",
    "it_nationality": "ivoriano",
},
{
    "num_code": "191",
    "alpha_2_code": "HR",
    "alpha_3_code": "HRV",
    "en_short_name": "Croatia",
    "nationality": "Croatian",
    "it_short_name": "Croazia",
    "it_nationality": "croato",
},
{
    "num_code": "192",
    "alpha_2_code": "CU",
    "alpha_3_code": "CUB",
    "en_short_name": "Cuba",
    "nationality": "Cuban",
    "it_short_name": "Cuba",
    "it_nationality": "cubano",
},
{
    "num_code": "531",
    "alpha_2_code": "CW",
    "alpha_3_code": "CUW",
    "en_short_name": "Curaçao",
    "nationality": "Curaçaoan",
    "it_short_name": "Curaçao",
    "it_nationality": "Curacao",
},
{
    "num_code": "196",
    "alpha_2_code": "CY",
    "alpha_3_code": "CYP",
    "en_short_name": "Cyprus",
    "nationality": "Cypriot",
    "it_short_name": "Cipro",
    "it_nationality": "cipriota",
},
{
    "num_code": "203",
    "alpha_2_code": "CZ",
    "alpha_3_code": "CZE",
    "en_short_name": "Czech Republic",
    "nationality": "Czech",
    "it_short_name": "Repubblica Ceca",
    "it_nationality": "ceco",
},
{
    "num_code": "208",
    "alpha_2_code": "DK",
    "alpha_3_code": "DNK",
    "en_short_name": "Denmark",
    "nationality": "Danish",
    "it_short_name": "Danimarca",
    "it_nationality": "danese",
},
{
    "num_code": "262",
    "alpha_2_code": "DJ",
    "alpha_3_code": "DJI",
    "en_short_name": "Djibouti",
    "nationality": "Djiboutian",
    "it_short_name": "Gibuti",
    "it_nationality": "Gibutiano",
},
{
    "num_code": "212",
    "alpha_2_code": "DM",
    "alpha_3_code": "DMA",
    "en_short_name": "Dominica",
    "nationality": "Dominican",
    "it_short_name": "La dominica",
    "it_nationality": "domenicano",
},
{
    "num_code": "214",
    "alpha_2_code": "DO",
    "alpha_3_code": "DOM",
    "en_short_name": "Dominican Republic",
    "nationality": "Dominican",
    "it_short_name": "Repubblica Dominicana",
    "it_nationality": "domenicano",
},
{
    "num_code": "218",
    "alpha_2_code": "EC",
    "alpha_3_code": "ECU",
    "en_short_name": "Ecuador",
    "nationality": "Ecuadorian",
    "it_short_name": "Ecuador",
    "it_nationality": "ecuadoriano",
},
{
    "num_code": "818",
    "alpha_2_code": "EG",
    "alpha_3_code": "EGY",
    "en_short_name": "Egypt",
    "nationality": "Egyptian",
    "it_short_name": "Egitto",
    "it_nationality": "egiziano",
},
{
    "num_code": "222",
    "alpha_2_code": "SV",
    "alpha_3_code": "SLV",
    "en_short_name": "El Salvador",
    "nationality": "Salvadoran",
    "it_short_name": "El Salvador",
    "it_nationality": "salvadoregno",
},
{
    "num_code": "226",
    "alpha_2_code": "GQ",
    "alpha_3_code": "GNQ",
    "en_short_name": "Equatorial Guinea",
    "nationality": "Equatorial Guinean, Equatoguinean",
    "it_short_name": "Guinea Equatoriale",
    "it_nationality": "Guinea Equatoriale, Equatoguineano",
},
{
    "num_code": "232",
    "alpha_2_code": "ER",
    "alpha_3_code": "ERI",
    "en_short_name": "Eritrea",
    "nationality": "Eritrean",
    "it_short_name": "Eritrea",
    "it_nationality": "Eritreo",
},
{
    "num_code": "233",
    "alpha_2_code": "EE",
    "alpha_3_code": "EST",
    "en_short_name": "Estonia",
    "nationality": "Estonian",
    "it_short_name": "Estonia",
    "it_nationality": "Estone",
},
{
    "num_code": "231",
    "alpha_2_code": "ET",
    "alpha_3_code": "ETH",
    "en_short_name": "Ethiopia",
    "nationality": "Ethiopian",
    "it_short_name": "Etiopia",
    "it_nationality": "etiope",
},
{
    "num_code": "238",
    "alpha_2_code": "FK",
    "alpha_3_code": "FLK",
    "en_short_name": "Falkland Islands (Malvinas)",
    "nationality": "Falkland Island",
    "it_short_name": "Isole Falkland (Malvine)",
    "it_nationality": "Isole Falkland",
},
{
    "num_code": "234",
    "alpha_2_code": "FO",
    "alpha_3_code": "FRO",
    "en_short_name": "Faroe Islands",
    "nationality": "Faroese",
    "it_short_name": "Isole Faroe",
    "it_nationality": "Faroese",
},
{
    "num_code": "242",
    "alpha_2_code": "FJ",
    "alpha_3_code": "FJI",
    "en_short_name": "Fiji",
    "nationality": "Fijian",
    "it_short_name": "Figi",
    "it_nationality": "Figiano",
},
{
    "num_code": "246",
    "alpha_2_code": "FI",
    "alpha_3_code": "FIN",
    "en_short_name": "Finland",
    "nationality": "Finnish",
    "it_short_name": "Finlandia",
    "it_nationality": "finlandese",
},
{
    "num_code": "250",
    "alpha_2_code": "FR",
    "alpha_3_code": "FRA",
    "en_short_name": "France",
    "nationality": "French",
    "it_short_name": "Francia",
    "it_nationality": "francese",
},
{
    "num_code": "254",
    "alpha_2_code": "GF",
    "alpha_3_code": "GUF",
    "en_short_name": "French Guiana",
    "nationality": "French Guianese",
    "it_short_name": "Guyana francese",
    "it_nationality": "Guyana francese",
},
{
    "num_code": "258",
    "alpha_2_code": "PF",
    "alpha_3_code": "PYF",
    "en_short_name": "French Polynesia",
    "nationality": "French Polynesian",
    "it_short_name": "Polinesia francese",
    "it_nationality": "Polinesiano francese",
},
{
    "num_code": "260",
    "alpha_2_code": "TF",
    "alpha_3_code": "ATF",
    "en_short_name": "French Southern Territories",
    "nationality": "French Southern Territories",
    "it_short_name": "Territori francesi australi",
    "it_nationality": "Territori francesi australi",
},
{
    "num_code": "266",
    "alpha_2_code": "GA",
    "alpha_3_code": "GAB",
    "en_short_name": "Gabon",
    "nationality": "Gabonese",
    "it_short_name": "Gabon",
    "it_nationality": "gabonese",
},
{
    "num_code": "270",
    "alpha_2_code": "GM",
    "alpha_3_code": "GMB",
    "en_short_name": "Gambia",
    "nationality": "Gambian",
    "it_short_name": "Gambia",
    "it_nationality": "Gambiano",
},
{
    "num_code": "268",
    "alpha_2_code": "GE",
    "alpha_3_code": "GEO",
    "en_short_name": "Georgia",
    "nationality": "Georgian",
    "it_short_name": "Georgia",
    "it_nationality": "georgiano",
},
{
    "num_code": "276",
    "alpha_2_code": "DE",
    "alpha_3_code": "DEU",
    "en_short_name": "Germany",
    "nationality": "German",
    "it_short_name": "Germania",
    "it_nationality": "tedesco",
},
{
    "num_code": "288",
    "alpha_2_code": "GH",
    "alpha_3_code": "GHA",
    "en_short_name": "Ghana",
    "nationality": "Ghanaian",
    "it_short_name": "Ghana",
    "it_nationality": "ghanese",
},
{
    "num_code": "292",
    "alpha_2_code": "GI",
    "alpha_3_code": "GIB",
    "en_short_name": "Gibraltar",
    "nationality": "Gibraltar",
    "it_short_name": "Gibilterra",
    "it_nationality": "Gibilterra",
},
{
    "num_code": "300",
    "alpha_2_code": "GR",
    "alpha_3_code": "GRC",
    "en_short_name": "Greece",
    "nationality": "Greek, Hellenic",
    "it_short_name": "Grecia",
    "it_nationality": "Greco, Ellenico",
},
{
    "num_code": "304",
    "alpha_2_code": "GL",
    "alpha_3_code": "GRL",
    "en_short_name": "Greenland",
    "nationality": "Greenlandic",
    "it_short_name": "Groenlandia",
    "it_nationality": "Groenlandese",
},
{
    "num_code": "308",
    "alpha_2_code": "GD",
    "alpha_3_code": "GRD",
    "en_short_name": "Grenada",
    "nationality": "Grenadian",
    "it_short_name": "Grenada",
    "it_nationality": "Grenadiano",
},
{
    "num_code": "312",
    "alpha_2_code": "GP",
    "alpha_3_code": "GLP",
    "en_short_name": "Guadeloupe",
    "nationality": "Guadeloupe",
    "it_short_name": "Guadalupa",
    "it_nationality": "Guadalupa",
},
{
    "num_code": "316",
    "alpha_2_code": "GU",
    "alpha_3_code": "GUM",
    "en_short_name": "Guam",
    "nationality": "Guamanian, Guambat",
    "it_short_name": "Guam",
    "it_nationality": "Guamaniano, Guambat",
},
{
    "num_code": "320",
    "alpha_2_code": "GT",
    "alpha_3_code": "GTM",
    "en_short_name": "Guatemala",
    "nationality": "Guatemalan",
    "it_short_name": "Guatemala",
    "it_nationality": "guatemalteco",
},
{
    "num_code": "831",
    "alpha_2_code": "GG",
    "alpha_3_code": "GGY",
    "en_short_name": "Guernsey",
    "nationality": "Channel Island",
    "it_short_name": "Guernsey",
    "it_nationality": "Isola del Canale",
},
{
    "num_code": "324",
    "alpha_2_code": "GN",
    "alpha_3_code": "GIN",
    "en_short_name": "Guinea",
    "nationality": "Guinean",
    "it_short_name": "Guinea",
    "it_nationality": "Guineano",
},
{
    "num_code": "624",
    "alpha_2_code": "GW",
    "alpha_3_code": "GNB",
    "en_short_name": "Guinea-Bissau",
    "nationality": "Bissau-Guinean",
    "it_short_name": "Guinea Bissau",
    "it_nationality": "Bissau-Guineano",
},
{
    "num_code": "328",
    "alpha_2_code": "GY",
    "alpha_3_code": "GUY",
    "en_short_name": "Guyana",
    "nationality": "Guyanese",
    "it_short_name": "Guyana",
    "it_nationality": "Guyana",
},
{
    "num_code": "332",
    "alpha_2_code": "HT",
    "alpha_3_code": "HTI",
    "en_short_name": "Haiti",
    "nationality": "Haitian",
    "it_short_name": "Haiti",
    "it_nationality": "haitiano",
},
{
    "num_code": "334",
    "alpha_2_code": "HM",
    "alpha_3_code": "HMD",
    "en_short_name": "Heard Island and McDonald Islands",
    "nationality": "Heard Island or McDonald Islands",
    "it_short_name": "Isole Heard e McDonald",
    "it_nationality": "Isole Heard o isole McDonald",
},
{
    "num_code": "336",
    "alpha_2_code": "VA",
    "alpha_3_code": "VAT",
    "en_short_name": "Vatican City State",
    "nationality": "Vatican",
    "it_short_name": "Stato della Città del Vaticano",
    "it_nationality": "Vaticano",
},
{
    "num_code": "340",
    "alpha_2_code": "HN",
    "alpha_3_code": "HND",
    "en_short_name": "Honduras",
    "nationality": "Honduran",
    "it_short_name": "Honduras",
    "it_nationality": "honduregno",
},
{
    "num_code": "344",
    "alpha_2_code": "HK",
    "alpha_3_code": "HKG",
    "en_short_name": "Hong Kong",
    "nationality": "Hong Kong, Hong Kongese",
    "it_short_name": "Hong Kong",
    "it_nationality": "Hong Kong, hongkonghese",
},
{
    "num_code": "348",
    "alpha_2_code": "HU",
    "alpha_3_code": "HUN",
    "en_short_name": "Hungary",
    "nationality": "Hungarian, Magyar",
    "it_short_name": "Ungheria",
    "it_nationality": "Ungherese, Magiaro",
},
{
    "num_code": "352",
    "alpha_2_code": "IS",
    "alpha_3_code": "ISL",
    "en_short_name": "Iceland",
    "nationality": "Icelandic",
    "it_short_name": "Islanda",
    "it_nationality": "islandese",
},
{
    "num_code": "356",
    "alpha_2_code": "IN",
    "alpha_3_code": "IND",
    "en_short_name": "India",
    "nationality": "Indian",
    "it_short_name": "India",
    "it_nationality": "indiano",
},
{
    "num_code": "360",
    "alpha_2_code": "ID",
    "alpha_3_code": "IDN",
    "en_short_name": "Indonesia",
    "nationality": "Indonesian",
    "it_short_name": "Indonesia",
    "it_nationality": "indonesiano",
},
{
    "num_code": "364",
    "alpha_2_code": "IR",
    "alpha_3_code": "IRN",
    "en_short_name": "Iran",
    "nationality": "Iranian, Persian",
    "it_short_name": "L'Iran",
    "it_nationality": "iraniano, persiano",
},
{
    "num_code": "368",
    "alpha_2_code": "IQ",
    "alpha_3_code": "IRQ",
    "en_short_name": "Iraq",
    "nationality": "Iraqi",
    "it_short_name": "Iraq",
    "it_nationality": "iracheno",
},
{
    "num_code": "372",
    "alpha_2_code": "IE",
    "alpha_3_code": "IRL",
    "en_short_name": "Ireland",
    "nationality": "Irish",
    "it_short_name": "Irlanda",
    "it_nationality": "irlandese",
},
{
    "num_code": "833",
    "alpha_2_code": "IM",
    "alpha_3_code": "IMN",
    "en_short_name": "Isle of Man",
    "nationality": "Manx",
    "it_short_name": "Isola di Man",
    "it_nationality": "Manx",
},
{
    "num_code": "376",
    "alpha_2_code": "IL",
    "alpha_3_code": "ISR",
    "en_short_name": "Israel",
    "nationality": "Israeli",
    "it_short_name": "Israele",
    "it_nationality": "israeliano",
},
{
    "num_code": "380",
    "alpha_2_code": "I",
    "alpha_3_code": "ITA",
    "en_short_name": "Italy",
    "nationality": "Italian",
    "it_short_name": "Italia",
    "it_nationality": "Italiano",
},
{
    "num_code": "388",
    "alpha_2_code": "JM",
    "alpha_3_code": "JAM",
    "en_short_name": "Jamaica",
    "nationality": "Jamaican",
    "it_short_name": "Giamaica",
    "it_nationality": "giamaicano",
},
{
    "num_code": "392",
    "alpha_2_code": "JP",
    "alpha_3_code": "JPN",
    "en_short_name": "Japan",
    "nationality": "Japanese",
    "it_short_name": "Giappone",
    "it_nationality": "giapponese",
},
{
    "num_code": "832",
    "alpha_2_code": "JE",
    "alpha_3_code": "JEY",
    "en_short_name": "Jersey",
    "nationality": "Channel Island",
    "it_short_name": "Maglia",
    "it_nationality": "Isola del Canale",
},
{
    "num_code": "400",
    "alpha_2_code": "JO",
    "alpha_3_code": "JOR",
    "en_short_name": "Jordan",
    "nationality": "Jordanian",
    "it_short_name": "Giordania",
    "it_nationality": "Giordano",
},
{
    "num_code": "398",
    "alpha_2_code": "KZ",
    "alpha_3_code": "KAZ",
    "en_short_name": "Kazakhstan",
    "nationality": "Kazakhstani, Kazakh",
    "it_short_name": "Kazakistan",
    "it_nationality": "Kazako, Kazako",
},
{
    "num_code": "404",
    "alpha_2_code": "KE",
    "alpha_3_code": "KEN",
    "en_short_name": "Kenya",
    "nationality": "Kenyan",
    "it_short_name": "Kenia",
    "it_nationality": "keniota",
},
{
    "num_code": "296",
    "alpha_2_code": "KI",
    "alpha_3_code": "KIR",
    "en_short_name": "Kiribati",
    "nationality": "I-Kiribati",
    "it_short_name": "Kiribati",
    "it_nationality": "Kiribati",
},
{
    "num_code": "408",
    "alpha_2_code": "KP",
    "alpha_3_code": "PRK",
    "en_short_name": "Korea (Democratic People's Republic of)",
    "nationality": "North Korean",
    "it_short_name": "Corea (Repubblica Popolare Democratica)",
    "it_nationality": "Corea del Nord",
},
{
    "num_code": "410",
    "alpha_2_code": "KR",
    "alpha_3_code": "KOR",
    "en_short_name": "Korea (Republic of)",
    "nationality": "South Korean",
    "it_short_name": "Corea (Repubblica di)",
    "it_nationality": "Corea del Sud",
},
{
    "num_code": "414",
    "alpha_2_code": "KW",
    "alpha_3_code": "KWT",
    "en_short_name": "Kuwait",
    "nationality": "Kuwaiti",
    "it_short_name": "Kuwait",
    "it_nationality": "kuwaitiano",
},
{
    "num_code": "417",
    "alpha_2_code": "KG",
    "alpha_3_code": "KGZ",
    "en_short_name": "Kyrgyzstan",
    "nationality": "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
    "it_short_name": "Kirghizistan",
    "it_nationality": "Kirghizistan, kirghiso, kirghiso, kirghiso",
},
{
    "num_code": "418",
    "alpha_2_code": "LA",
    "alpha_3_code": "LAO",
    "en_short_name": "Lao People's Democratic Republic",
    "nationality": "Lao, Laotian",
    "it_short_name": "Repubblica Democratica Popolare del Laos",
    "it_nationality": "Lao, Laotian",
},
{
    "num_code": "428",
    "alpha_2_code": "LV",
    "alpha_3_code": "LVA",
    "en_short_name": "Latvia",
    "nationality": "Latvian",
    "it_short_name": "Lettonia",
    "it_nationality": "lettone",
},
{
    "num_code": "422",
    "alpha_2_code": "LB",
    "alpha_3_code": "LBN",
    "en_short_name": "Lebanon",
    "nationality": "Lebanese",
    "it_short_name": "Libano",
    "it_nationality": "libanese",
},
{
    "num_code": "426",
    "alpha_2_code": "LS",
    "alpha_3_code": "LSO",
    "en_short_name": "Lesotho",
    "nationality": "Basotho",
    "it_short_name": "Lesoto",
    "it_nationality": "Basotho",
},
{
    "num_code": "430",
    "alpha_2_code": "LR",
    "alpha_3_code": "LBR",
    "en_short_name": "Liberia",
    "nationality": "Liberian",
    "it_short_name": "Liberia",
    "it_nationality": "Liberiano",
},
{
    "num_code": "434",
    "alpha_2_code": "LY",
    "alpha_3_code": "LBY",
    "en_short_name": "Libya",
    "nationality": "Libyan",
    "it_short_name": "Libia",
    "it_nationality": "libico",
},
{
    "num_code": "438",
    "alpha_2_code": "LI",
    "alpha_3_code": "LIE",
    "en_short_name": "Liechtenstein",
    "nationality": "Liechtenstein",
    "it_short_name": "Liechtenstein",
    "it_nationality": "Liechtenstein",
},
{
    "num_code": "440",
    "alpha_2_code": "LT",
    "alpha_3_code": "LTU",
    "en_short_name": "Lithuania",
    "nationality": "Lithuanian",
    "it_short_name": "Lituania",
    "it_nationality": "lituano",
},
{
    "num_code": "442",
    "alpha_2_code": "LU",
    "alpha_3_code": "LUX",
    "en_short_name": "Luxembourg",
    "nationality": "Luxembourg, Luxembourgish",
    "it_short_name": "Lussemburgo",
    "it_nationality": "Lussemburgo, lussemburghese",
},
{
    "num_code": "446",
    "alpha_2_code": "MO",
    "alpha_3_code": "MAC",
    "en_short_name": "Macao",
    "nationality": "Macanese, Chinese",
    "it_short_name": "Macao",
    "it_nationality": "Macanese, cinese",
},
{
    "num_code": "807",
    "alpha_2_code": "MK",
    "alpha_3_code": "MKD",
    "en_short_name": "Macedonia (the former Yugoslav Republic of)",
    "nationality": "Macedonian",
    "it_short_name": "Macedonia (ex Repubblica Jugoslava di)",
    "it_nationality": "macedone",
},
{
    "num_code": "450",
    "alpha_2_code": "MG",
    "alpha_3_code": "MDG",
    "en_short_name": "Madagascar",
    "nationality": "Malagasy",
    "it_short_name": "Madagascar",
    "it_nationality": "malgascio",
},
{
    "num_code": "454",
    "alpha_2_code": "MW",
    "alpha_3_code": "MWI",
    "en_short_name": "Malawi",
    "nationality": "Malawian",
    "it_short_name": "Malawi",
    "it_nationality": "Malawiano",
},
{
    "num_code": "458",
    "alpha_2_code": "MY",
    "alpha_3_code": "MYS",
    "en_short_name": "Malaysia",
    "nationality": "Malaysian",
    "it_short_name": "Malaysia",
    "it_nationality": "Malese",
},
{
    "num_code": "462",
    "alpha_2_code": "MV",
    "alpha_3_code": "MDV",
    "en_short_name": "Maldives",
    "nationality": "Maldivian",
    "it_short_name": "Maldive",
    "it_nationality": "Maldiviano",
},
{
    "num_code": "466",
    "alpha_2_code": "ML",
    "alpha_3_code": "MLI",
    "en_short_name": "Mali",
    "nationality": "Malian, Malinese",
    "it_short_name": "Mali",
    "it_nationality": "Maliano, Malinese",
},
{
    "num_code": "470",
    "alpha_2_code": "MT",
    "alpha_3_code": "MLT",
    "en_short_name": "Malta",
    "nationality": "Maltese",
    "it_short_name": "Malta",
    "it_nationality": "maltese",
},
{
    "num_code": "584",
    "alpha_2_code": "MH",
    "alpha_3_code": "MHL",
    "en_short_name": "Marshall Islands",
    "nationality": "Marshallese",
    "it_short_name": "Isole Marshall",
    "it_nationality": "Marshallese",
},
{
    "num_code": "474",
    "alpha_2_code": "MQ",
    "alpha_3_code": "MTQ",
    "en_short_name": "Martinique",
    "nationality": "Martiniquais, Martinican",
    "it_short_name": "Martinica",
    "it_nationality": "Martinicano, Martiniquais",
},
{
    "num_code": "478",
    "alpha_2_code": "MR",
    "alpha_3_code": "MRT",
    "en_short_name": "Mauritania",
    "nationality": "Mauritanian",
    "it_short_name": "Mauritania",
    "it_nationality": "Mauritano",
},
{
    "num_code": "480",
    "alpha_2_code": "MU",
    "alpha_3_code": "MUS",
    "en_short_name": "Mauritius",
    "nationality": "Mauritian",
    "it_short_name": "Maurizio",
    "it_nationality": "mauriziano",
},
{
    "num_code": "175",
    "alpha_2_code": "YT",
    "alpha_3_code": "MYT",
    "en_short_name": "Mayotte",
    "nationality": "Mahoran",
    "it_short_name": "Majotte",
    "it_nationality": "Mahoran",
},
{
    "num_code": "484",
    "alpha_2_code": "MX",
    "alpha_3_code": "MEX",
    "en_short_name": "Mexico",
    "nationality": "Mexican",
    "it_short_name": "Messico",
    "it_nationality": "messicano",
},
{
    "num_code": "583",
    "alpha_2_code": "FM",
    "alpha_3_code": "FSM",
    "en_short_name": "Micronesia (Federated States of)",
    "nationality": "Micronesian",
    "it_short_name": "Micronesia (Stati Federati di)",
    "it_nationality": "Micronesiano",
},
{
    "num_code": "498",
    "alpha_2_code": "MD",
    "alpha_3_code": "MDA",
    "en_short_name": "Moldova (Republic of)",
    "nationality": "Moldovan",
    "it_short_name": "Moldavia (Repubblica di)",
    "it_nationality": "Moldavo",
},
{
    "num_code": "492",
    "alpha_2_code": "MC",
    "alpha_3_code": "MCO",
    "en_short_name": "Monaco",
    "nationality": "Monégasque, Monacan",
    "it_short_name": "Monaco",
    "it_nationality": "Monegasco, monacano",
},
{
    "num_code": "496",
    "alpha_2_code": "MN",
    "alpha_3_code": "MNG",
    "en_short_name": "Mongolia",
    "nationality": "Mongolian",
    "it_short_name": "Mongolia",
    "it_nationality": "mongolo",
},
{
    "num_code": "499",
    "alpha_2_code": "ME",
    "alpha_3_code": "MNE",
    "en_short_name": "Montenegro",
    "nationality": "Montenegrin",
    "it_short_name": "Montenegro",
    "it_nationality": "montenegrino",
},
{
    "num_code": "500",
    "alpha_2_code": "MS",
    "alpha_3_code": "MSR",
    "en_short_name": "Montserrat",
    "nationality": "Montserratian",
    "it_short_name": "Monteserrato",
    "it_nationality": "Montserratiano",
},
{
    "num_code": "504",
    "alpha_2_code": "MA",
    "alpha_3_code": "MAR",
    "en_short_name": "Morocco",
    "nationality": "Moroccan",
    "it_short_name": "Marocco",
    "it_nationality": "marocchino",
},
{
    "num_code": "508",
    "alpha_2_code": "MZ",
    "alpha_3_code": "MOZ",
    "en_short_name": "Mozambique",
    "nationality": "Mozambican",
    "it_short_name": "Mozambico",
    "it_nationality": "Mozambicano",
},
{
    "num_code": "104",
    "alpha_2_code": "MM",
    "alpha_3_code": "MMR",
    "en_short_name": "Myanmar",
    "nationality": "Burmese",
    "it_short_name": "Birmania",
    "it_nationality": "birmano",
},
{
    "num_code": "516",
    "alpha_2_code": "NA",
    "alpha_3_code": "NAM",
    "en_short_name": "Namibia",
    "nationality": "Namibian",
    "it_short_name": "La Namibia",
    "it_nationality": "Namibiano",
},
{
    "num_code": "520",
    "alpha_2_code": "NR",
    "alpha_3_code": "NRU",
    "en_short_name": "Nauru",
    "nationality": "Nauruan",
    "it_short_name": "Nauru",
    "it_nationality": "Nauruano",
},
{
    "num_code": "524",
    "alpha_2_code": "NP",
    "alpha_3_code": "NPL",
    "en_short_name": "Nepal",
    "nationality": "Nepali, Nepalese",
    "it_short_name": "Nepal",
    "it_nationality": "Nepalese, nepalese",
},
{
    "num_code": "528",
    "alpha_2_code": "NL",
    "alpha_3_code": "NLD",
    "en_short_name": "Netherlands",
    "nationality": "Dutch, Netherlandic",
    "it_short_name": "Paesi Bassi",
    "it_nationality": "olandese, olandese",
},
{
    "num_code": "540",
    "alpha_2_code": "NC",
    "alpha_3_code": "NCL",
    "en_short_name": "New Caledonia",
    "nationality": "New Caledonian",
    "it_short_name": "Nuova Caledonia",
    "it_nationality": "Nuova Caledonia",
},
{
    "num_code": "554",
    "alpha_2_code": "NZ",
    "alpha_3_code": "NZL",
    "en_short_name": "New Zealand",
    "nationality": "New Zealand, NZ",
    "it_short_name": "Nuova Zelanda",
    "it_nationality": "Nuova Zelanda, NZ",
},
{
    "num_code": "558",
    "alpha_2_code": "NI",
    "alpha_3_code": "NIC",
    "en_short_name": "Nicaragua",
    "nationality": "Nicaraguan",
    "it_short_name": "Nicaragua",
    "it_nationality": "Nicaraguense",
},
{
    "num_code": "562",
    "alpha_2_code": "NE",
    "alpha_3_code": "NER",
    "en_short_name": "Niger",
    "nationality": "Nigerien",
    "it_short_name": "Niger",
    "it_nationality": "nigeriano",
},
{
    "num_code": "566",
    "alpha_2_code": "NG",
    "alpha_3_code": "NGA",
    "en_short_name": "Nigeria",
    "nationality": "Nigerian",
    "it_short_name": "Nigeria",
    "it_nationality": "nigeriano",
},
{
    "num_code": "570",
    "alpha_2_code": "NU",
    "alpha_3_code": "NIU",
    "en_short_name": "Niue",
    "nationality": "Niuean",
    "it_short_name": "Niue",
    "it_nationality": "Niueano",
},
{
    "num_code": "574",
    "alpha_2_code": "NF",
    "alpha_3_code": "NFK",
    "en_short_name": "Norfolk Island",
    "nationality": "Norfolk Island",
    "it_short_name": "Isola Norfolk",
    "it_nationality": "Isola Norfolk",
},
{
    "num_code": "580",
    "alpha_2_code": "MP",
    "alpha_3_code": "MNP",
    "en_short_name": "Northern Mariana Islands",
    "nationality": "Northern Marianan",
    "it_short_name": "Isole Marianne Settentrionali",
    "it_nationality": "Marianne Settentrionali",
},
{
    "num_code": "578",
    "alpha_2_code": "NO",
    "alpha_3_code": "NOR",
    "en_short_name": "Norway",
    "nationality": "Norwegian",
    "it_short_name": "Norvegia",
    "it_nationality": "norvegese",
},
{
    "num_code": "512",
    "alpha_2_code": "OM",
    "alpha_3_code": "OMN",
    "en_short_name": "Oman",
    "nationality": "Omani",
    "it_short_name": "Oman",
    "it_nationality": "Omaniano",
},
{
    "num_code": "586",
    "alpha_2_code": "PK",
    "alpha_3_code": "PAK",
    "en_short_name": "Pakistan",
    "nationality": "Pakistani",
    "it_short_name": "Pakistan",
    "it_nationality": "pakistano",
},
{
    "num_code": "585",
    "alpha_2_code": "PW",
    "alpha_3_code": "PLW",
    "en_short_name": "Palau",
    "nationality": "Palauan",
    "it_short_name": "Palau",
    "it_nationality": "Palauano",
},
{
    "num_code": "275",
    "alpha_2_code": "PS",
    "alpha_3_code": "PSE",
    "en_short_name": "Palestine, State of",
    "nationality": "Palestinian",
    "it_short_name": "Palestina, Stato di",
    "it_nationality": "palestinese",
},
{
    "num_code": "591",
    "alpha_2_code": "PA",
    "alpha_3_code": "PAN",
    "en_short_name": "Panama",
    "nationality": "Panamanian",
    "it_short_name": "Panama",
    "it_nationality": "panamense",
},
{
    "num_code": "598",
    "alpha_2_code": "PG",
    "alpha_3_code": "PNG",
    "en_short_name": "Papua New Guinea",
    "nationality": "Papua New Guinean, Papuan",
    "it_short_name": "Papua Nuova Guinea",
    "it_nationality": "Papua Nuova Guinea, Papua",
},
{
    "num_code": "600",
    "alpha_2_code": "PY",
    "alpha_3_code": "PRY",
    "en_short_name": "Paraguay",
    "nationality": "Paraguayan",
    "it_short_name": "Il Paraguay",
    "it_nationality": "Paraguaiano",
},
{
    "num_code": "604",
    "alpha_2_code": "PE",
    "alpha_3_code": "PER",
    "en_short_name": "Peru",
    "nationality": "Peruvian",
    "it_short_name": "Perù",
    "it_nationality": "peruviano",
},
{
    "num_code": "608",
    "alpha_2_code": "PH",
    "alpha_3_code": "PHL",
    "en_short_name": "Philippines",
    "nationality": "Philippine, Filipino",
    "it_short_name": "Filippine",
    "it_nationality": "filippino, filippino",
},
{
    "num_code": "612",
    "alpha_2_code": "PN",
    "alpha_3_code": "PCN",
    "en_short_name": "Pitcairn",
    "nationality": "Pitcairn Island",
    "it_short_name": "Pitcairn",
    "it_nationality": "Isola di Pitcairn",
},
{
    "num_code": "616",
    "alpha_2_code": "PL",
    "alpha_3_code": "POL",
    "en_short_name": "Poland",
    "nationality": "Polish",
    "it_short_name": "Polonia",
    "it_nationality": "Polacco",
},
{
    "num_code": "620",
    "alpha_2_code": "PT",
    "alpha_3_code": "PRT",
    "en_short_name": "Portugal",
    "nationality": "Portuguese",
    "it_short_name": "Portogallo",
    "it_nationality": "portoghese",
},
{
    "num_code": "630",
    "alpha_2_code": "PR",
    "alpha_3_code": "PRI",
    "en_short_name": "Puerto Rico",
    "nationality": "Puerto Rican",
    "it_short_name": "Portorico",
    "it_nationality": "Portoricano",
},
{
    "num_code": "634",
    "alpha_2_code": "QA",
    "alpha_3_code": "QAT",
    "en_short_name": "Qatar",
    "nationality": "Qatari",
    "it_short_name": "Qatar",
    "it_nationality": "Qatariano",
},
{
    "num_code": "638",
    "alpha_2_code": "RE",
    "alpha_3_code": "REU",
    "en_short_name": "Réunion",
    "nationality": "Réunionese, Réunionnais",
    "it_short_name": "Riunione",
    "it_nationality": "Riunionese, Riunionenais",
},
{
    "num_code": "642",
    "alpha_2_code": "RO",
    "alpha_3_code": "ROU",
    "en_short_name": "Romania",
    "nationality": "Romanian",
    "it_short_name": "Romania",
    "it_nationality": "rumeno",
},
{
    "num_code": "643",
    "alpha_2_code": "RU",
    "alpha_3_code": "RUS",
    "en_short_name": "Russian Federation",
    "nationality": "Russian",
    "it_short_name": "Federazione Russa",
    "it_nationality": "russo",
},
{
    "num_code": "646",
    "alpha_2_code": "RW",
    "alpha_3_code": "RWA",
    "en_short_name": "Rwanda",
    "nationality": "Rwandan",
    "it_short_name": "Ruanda",
    "it_nationality": "Ruandese",
},
{
    "num_code": "652",
    "alpha_2_code": "BL",
    "alpha_3_code": "BLM",
    "en_short_name": "Saint Barthélemy",
    "nationality": "Barthélemois",
    "it_short_name": "Saint-Barthélemy",
    "it_nationality": "Bartolomeo",
},
{
    "num_code": "654",
    "alpha_2_code": "SH",
    "alpha_3_code": "SHN",
    "en_short_name": "Saint Helena, Ascension and Tristan da Cunha",
    "nationality": "Saint Helenian",
    "it_short_name": "Sant'Elena, Ascensione e Tristano da Cunha",
    "it_nationality": "Sant'Elena",
},
{
    "num_code": "659",
    "alpha_2_code": "KN",
    "alpha_3_code": "KNA",
    "en_short_name": "Saint Kitts and Nevis",
    "nationality": "Kittitian or Nevisian",
    "it_short_name": "Saint Kitts e Nevis",
    "it_nationality": "Kittitiano o Nevisiano",
},
{
    "num_code": "662",
    "alpha_2_code": "LC",
    "alpha_3_code": "LCA",
    "en_short_name": "Saint Lucia",
    "nationality": "Saint Lucian",
    "it_short_name": "Santa Lucia",
    "it_nationality": "Santa Lucia",
},
{
    "num_code": "663",
    "alpha_2_code": "MF",
    "alpha_3_code": "MAF",
    "en_short_name": "Saint Martin (French part)",
    "nationality": "Saint-Martinoise",
    "it_short_name": "Saint Martin (parte francese)",
    "it_nationality": "Saint-Martinoise",
},
{
    "num_code": "666",
    "alpha_2_code": "PM",
    "alpha_3_code": "SPM",
    "en_short_name": "Saint Pierre and Miquelon",
    "nationality": "Saint-Pierrais or Miquelonnais",
    "it_short_name": "Saint Pierre e Miquelon",
    "it_nationality": "Saint-Pierrais o Miquelonnais",
},
{
    "num_code": "670",
    "alpha_2_code": "VC",
    "alpha_3_code": "VCT",
    "en_short_name": "Saint Vincent and the Grenadines",
    "nationality": "Saint Vincentian, Vincentian",
    "it_short_name": "Saint Vincent e Grenadine",
    "it_nationality": "San vincenziano, vincenziano",
},
{
    "num_code": "882",
    "alpha_2_code": "WS",
    "alpha_3_code": "WSM",
    "en_short_name": "Samoa",
    "nationality": "Samoan",
    "it_short_name": "Samoa",
    "it_nationality": "Samoano",
},
{
    "num_code": "674",
    "alpha_2_code": "SM",
    "alpha_3_code": "SMR",
    "en_short_name": "San Marino",
    "nationality": "Sammarinese",
    "it_short_name": "San Marino",
    "it_nationality": "Sammarinese",
},
{
    "num_code": "678",
    "alpha_2_code": "ST",
    "alpha_3_code": "STP",
    "en_short_name": "Sao Tome and Principe",
    "nationality": "São Toméan",
    "it_short_name": "Sao Tome e Principe",
    "it_nationality": "São Tomé",
},
{
    "num_code": "682",
    "alpha_2_code": "SA",
    "alpha_3_code": "SAU",
    "en_short_name": "Saudi Arabia",
    "nationality": "Saudi, Saudi Arabian",
    "it_short_name": "Arabia Saudita",
    "it_nationality": "saudita, saudita",
},
{
    "num_code": "686",
    "alpha_2_code": "SN",
    "alpha_3_code": "SEN",
    "en_short_name": "Senegal",
    "nationality": "Senegalese",
    "it_short_name": "Senegal",
    "it_nationality": "senegalese",
},
{
    "num_code": "688",
    "alpha_2_code": "RS",
    "alpha_3_code": "SRB",
    "en_short_name": "Serbia",
    "nationality": "Serbian",
    "it_short_name": "Serbia",
    "it_nationality": "serbo",
},
{
    "num_code": "690",
    "alpha_2_code": "SC",
    "alpha_3_code": "SYC",
    "en_short_name": "Seychelles",
    "nationality": "Seychellois",
    "it_short_name": "Le Seychelles",
    "it_nationality": "Seychellese",
},
{
    "num_code": "694",
    "alpha_2_code": "SL",
    "alpha_3_code": "SLE",
    "en_short_name": "Sierra Leone",
    "nationality": "Sierra Leonean",
    "it_short_name": "Sierra Leone",
    "it_nationality": "Sierra Leone",
},
{
    "num_code": "702",
    "alpha_2_code": "SG",
    "alpha_3_code": "SGP",
    "en_short_name": "Singapore",
    "nationality": "Singaporean",
    "it_short_name": "Singapore",
    "it_nationality": "Singaporeano",
},
{
    "num_code": "534",
    "alpha_2_code": "SX",
    "alpha_3_code": "SXM",
    "en_short_name": "Sint Maarten (Dutch part)",
    "nationality": "Sint Maarten",
    "it_short_name": "Sint Maarten (parte olandese)",
    "it_nationality": "Sint Maarten",
},
{
    "num_code": "703",
    "alpha_2_code": "SK",
    "alpha_3_code": "SVK",
    "en_short_name": "Slovakia",
    "nationality": "Slovak",
    "it_short_name": "Slovacchia",
    "it_nationality": "slovacco",
},
{
    "num_code": "705",
    "alpha_2_code": "SI",
    "alpha_3_code": "SVN",
    "en_short_name": "Slovenia",
    "nationality": "Slovenian, Slovene",
    "it_short_name": "Slovenia",
    "it_nationality": "sloveno, sloveno",
},
{
    "num_code": "90",
    "alpha_2_code": "SB",
    "alpha_3_code": "SLB",
    "en_short_name": "Solomon Islands",
    "nationality": "Solomon Island",
    "it_short_name": "Isole Salomone",
    "it_nationality": "Isole Salomone",
},
{
    "num_code": "706",
    "alpha_2_code": "SO",
    "alpha_3_code": "SOM",
    "en_short_name": "Somalia",
    "nationality": "Somali, Somalian",
    "it_short_name": "Somalia",
    "it_nationality": "Somalo, somalo",
},
{
    "num_code": "710",
    "alpha_2_code": "ZA",
    "alpha_3_code": "ZAF",
    "en_short_name": "South Africa",
    "nationality": "South African",
    "it_short_name": "Sudafrica",
    "it_nationality": "sudafricano",
},
{
    "num_code": "239",
    "alpha_2_code": "GS",
    "alpha_3_code": "SGS",
    "en_short_name": "South Georgia and the South Sandwich Islands",
    "nationality": "South Georgia or South Sandwich Islands",
    "it_short_name": "Georgia del Sud e Isole Sandwich Meridionali",
    "it_nationality": "Georgia del Sud o Isole Sandwich Meridionali",
},
{
    "num_code": "728",
    "alpha_2_code": "SS",
    "alpha_3_code": "SSD",
    "en_short_name": "South Sudan",
    "nationality": "South Sudanese",
    "it_short_name": "Sudan del Sud",
    "it_nationality": "Sudanese del Sud",
},
{
    "num_code": "724",
    "alpha_2_code": "ES",
    "alpha_3_code": "ESP",
    "en_short_name": "Spain",
    "nationality": "Spanish",
    "it_short_name": "Spagna",
    "it_nationality": "spagnolo",
},
{
    "num_code": "144",
    "alpha_2_code": "LK",
    "alpha_3_code": "LKA",
    "en_short_name": "Sri Lanka",
    "nationality": "Sri Lankan",
    "it_short_name": "Sri Lanka",
    "it_nationality": "dello Sri Lanka",
},
{
    "num_code": "729",
    "alpha_2_code": "SD",
    "alpha_3_code": "SDN",
    "en_short_name": "Sudan",
    "nationality": "Sudanese",
    "it_short_name": "Sudan",
    "it_nationality": "sudanese",
},
{
    "num_code": "740",
    "alpha_2_code": "SR",
    "alpha_3_code": "SUR",
    "en_short_name": "Suriname",
    "nationality": "Surinamese",
    "it_short_name": "Suriname",
    "it_nationality": "Surinamese",
},
{
    "num_code": "744",
    "alpha_2_code": "SJ",
    "alpha_3_code": "SJM",
    "en_short_name": "Svalbard and Jan Mayen",
    "nationality": "Svalbard",
    "it_short_name": "Svalbard e Jan Mayen",
    "it_nationality": "Isole Svalbard",
},
{
    "num_code": "748",
    "alpha_2_code": "SZ",
    "alpha_3_code": "SWZ",
    "en_short_name": "Swaziland",
    "nationality": "Swazi",
    "it_short_name": "Lo Swaziland",
    "it_nationality": "svazita",
},
{
    "num_code": "752",
    "alpha_2_code": "SE",
    "alpha_3_code": "SWE",
    "en_short_name": "Sweden",
    "nationality": "Swedish",
    "it_short_name": "Svezia",
    "it_nationality": "svedese",
},
{
    "num_code": "756",
    "alpha_2_code": "CH",
    "alpha_3_code": "CHE",
    "en_short_name": "Switzerland",
    "nationality": "Swiss",
    "it_short_name": "Svizzera",
    "it_nationality": "svizzero",
},
{
    "num_code": "760",
    "alpha_2_code": "SY",
    "alpha_3_code": "SYR",
    "en_short_name": "Syrian Arab Republic",
    "nationality": "Syrian",
    "it_short_name": "Repubblica araba siriana",
    "it_nationality": "siriano",
},
{
    "num_code": "158",
    "alpha_2_code": "TW",
    "alpha_3_code": "TWN",
    "en_short_name": "Taiwan, Province of China",
    "nationality": "Chinese, Taiwanese",
    "it_short_name": "Taiwan, provincia della Cina",
    "it_nationality": "Cinese, Taiwanese",
},
{
    "num_code": "762",
    "alpha_2_code": "TJ",
    "alpha_3_code": "TJK",
    "en_short_name": "Tajikistan",
    "nationality": "Tajikistani",
    "it_short_name": "Tagikistan",
    "it_nationality": "Tagikistan",
},
{
    "num_code": "834",
    "alpha_2_code": "TZ",
    "alpha_3_code": "TZA",
    "en_short_name": "Tanzania, United Republic of",
    "nationality": "Tanzanian",
    "it_short_name": "Tanzania, Repubblica Unita di",
    "it_nationality": "tanzaniano",
},
{
    "num_code": "764",
    "alpha_2_code": "TH",
    "alpha_3_code": "THA",
    "en_short_name": "Thailand",
    "nationality": "Thai",
    "it_short_name": "Thailandia",
    "it_nationality": "tailandese",
},
{
    "num_code": "626",
    "alpha_2_code": "TL",
    "alpha_3_code": "TLS",
    "en_short_name": "Timor-Leste",
    "nationality": "Timorese",
    "it_short_name": "Timor Est",
    "it_nationality": "Timorese",
},
{
    "num_code": "768",
    "alpha_2_code": "TG",
    "alpha_3_code": "TGO",
    "en_short_name": "Togo",
    "nationality": "Togolese",
    "it_short_name": "Togo",
    "it_nationality": "Togolese",
},
{
    "num_code": "772",
    "alpha_2_code": "TK",
    "alpha_3_code": "TKL",
    "en_short_name": "Tokelau",
    "nationality": "Tokelauan",
    "it_short_name": "Tokelau",
    "it_nationality": "Tokelauano",
},
{
    "num_code": "776",
    "alpha_2_code": "TO",
    "alpha_3_code": "TON",
    "en_short_name": "Tonga",
    "nationality": "Tongan",
    "it_short_name": "Tonga",
    "it_nationality": "Tongano",
},
{
    "num_code": "780",
    "alpha_2_code": "TT",
    "alpha_3_code": "TTO",
    "en_short_name": "Trinidad and Tobago",
    "nationality": "Trinidadian or Tobagonian",
    "it_short_name": "Trinidad e Tobago",
    "it_nationality": "Trinidad o Tobagoniano",
},
{
    "num_code": "788",
    "alpha_2_code": "TN",
    "alpha_3_code": "TUN",
    "en_short_name": "Tunisia",
    "nationality": "Tunisian",
    "it_short_name": "Tunisia",
    "it_nationality": "tunisino",
},
{
    "num_code": "792",
    "alpha_2_code": "TR",
    "alpha_3_code": "TUR",
    "en_short_name": "Turkey",
    "nationality": "Turkish",
    "it_short_name": "Tacchino",
    "it_nationality": "turco",
},
{
    "num_code": "795",
    "alpha_2_code": "TM",
    "alpha_3_code": "TKM",
    "en_short_name": "Turkmenistan",
    "nationality": "Turkmen",
    "it_short_name": "Turkmenistan",
    "it_nationality": "Turkmeno",
},
{
    "num_code": "796",
    "alpha_2_code": "TC",
    "alpha_3_code": "TCA",
    "en_short_name": "Turks and Caicos Islands",
    "nationality": "Turks and Caicos Island",
    "it_short_name": "Isole Turks e Caicos",
    "it_nationality": "Isole Turks e Caicos",
},
{
    "num_code": "798",
    "alpha_2_code": "TV",
    "alpha_3_code": "TUV",
    "en_short_name": "Tuvalu",
    "nationality": "Tuvaluan",
    "it_short_name": "Tuvalu",
    "it_nationality": "Tuvaluano",
},
{
    "num_code": "800",
    "alpha_2_code": "UG",
    "alpha_3_code": "UGA",
    "en_short_name": "Uganda",
    "nationality": "Ugandan",
    "it_short_name": "Uganda",
    "it_nationality": "Ugandesi",
},
{
    "num_code": "804",
    "alpha_2_code": "UA",
    "alpha_3_code": "UKR",
    "en_short_name": "Ukraine",
    "nationality": "Ukrainian",
    "it_short_name": "Ucraina",
    "it_nationality": "ucraino",
},
{
    "num_code": "784",
    "alpha_2_code": "AE",
    "alpha_3_code": "ARE",
    "en_short_name": "United Arab Emirates",
    "nationality": "Emirati, Emirian, Emiri",
    "it_short_name": "Emirati Arabi Uniti",
    "it_nationality": "Emiratino, Emiro, Emiri",
},
{
    "num_code": "826",
    "alpha_2_code": "GB",
    "alpha_3_code": "GBR",
    "en_short_name": "United Kingdom of Great Britain and Northern Ireland",
    "nationality": "British, UK",
    "it_short_name": "Regno Unito di Gran Bretagna e Irlanda del Nord",
    "it_nationality": "Britannico, Regno Unito",
},
{
    "num_code": "581",
    "alpha_2_code": "UM",
    "alpha_3_code": "UMI",
    "en_short_name": "United States Minor Outlying Islands",
    "nationality": "American",
    "it_short_name": "Isole minori esterne degli Stati Uniti",
    "it_nationality": "americano",
},
{
    "num_code": "840",
    "alpha_2_code": "US",
    "alpha_3_code": "USA",
    "en_short_name": "United States of America",
    "nationality": "American",
    "it_short_name": "Stati Uniti d'America",
    "it_nationality": "americano",
},
{
    "num_code": "858",
    "alpha_2_code": "UY",
    "alpha_3_code": "URY",
    "en_short_name": "Uruguay",
    "nationality": "Uruguayan",
    "it_short_name": "Uruguay",
    "it_nationality": "uruguaiano",
},
{
    "num_code": "860",
    "alpha_2_code": "UZ",
    "alpha_3_code": "UZB",
    "en_short_name": "Uzbekistan",
    "nationality": "Uzbekistani, Uzbek",
    "it_short_name": "Uzbekistan",
    "it_nationality": "Uzbekistan, Uzbeko",
},
{
    "num_code": "548",
    "alpha_2_code": "VU",
    "alpha_3_code": "VUT",
    "en_short_name": "Vanuatu",
    "nationality": "Ni-Vanuatu, Vanuatuan",
    "it_short_name": "Vanuatu",
    "it_nationality": "Ni-Vanuatu, Vanuatu",
},
{
    "num_code": "862",
    "alpha_2_code": "VE",
    "alpha_3_code": "VEN",
    "en_short_name": "Venezuela (Bolivarian Republic of)",
    "nationality": "Venezuelan",
    "it_short_name": "Venezuela (Repubblica Bolivariana del)",
    "it_nationality": "venezuelano",
},
{
    "num_code": "704",
    "alpha_2_code": "VN",
    "alpha_3_code": "VNM",
    "en_short_name": "Vietnam",
    "nationality": "Vietnamese",
    "it_short_name": "Vietnam",
    "it_nationality": "vietnamita",
},
{
    "num_code": "92",
    "alpha_2_code": "VG",
    "alpha_3_code": "VGB",
    "en_short_name": "Virgin Islands (British)",
    "nationality": "British Virgin Island",
    "it_short_name": "Isole Vergini (Britanniche)",
    "it_nationality": "Isole Vergini Britanniche",
},
{
    "num_code": "850",
    "alpha_2_code": "VI",
    "alpha_3_code": "VIR",
    "en_short_name": "Virgin Islands (U.S.)",
    "nationality": "U.S. Virgin Island",
    "it_short_name": "Isole Vergini (Stati Uniti)",
    "it_nationality": "Isole Vergini americane",
},
{
    "num_code": "876",
    "alpha_2_code": "WF",
    "alpha_3_code": "WLF",
    "en_short_name": "Wallis and Futuna",
    "nationality": "Wallis and Futuna, Wallisian or Futunan",
    "it_short_name": "Wallis e Futuna",
    "it_nationality": "Wallis e Futuna, Wallisiano o Futunano",
},
{
    "num_code": "732",
    "alpha_2_code": "EH",
    "alpha_3_code": "ESH",
    "en_short_name": "Western Sahara",
    "nationality": "Sahrawi, Sahrawian, Sahraouian",
    "it_short_name": "Sahara Occidentale",
    "it_nationality": "Saharawi, Saharawi, Saharawi",
},
{
    "num_code": "887",
    "alpha_2_code": "YE",
    "alpha_3_code": "YEM",
    "en_short_name": "Yemen",
    "nationality": "Yemeni",
    "it_short_name": "Yemen",
    "it_nationality": "yemenita",
},
{
    "num_code": "894",
    "alpha_2_code": "ZM",
    "alpha_3_code": "ZMB",
    "en_short_name": "Zambia",
    "nationality": "Zambian",
    "it_short_name": "Zambia",
    "it_nationality": "Zambiano",
},
{
    "num_code": "716",
    "alpha_2_code": "ZW",
    "alpha_3_code": "ZWE",
    "en_short_name": "Zimbabwe",
    "nationality": "Zimbabwean",
    "it_short_name": "Zimbabwe",
    "it_nationality": "dello Zimbabwe",
  },
];
