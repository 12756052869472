import React, { useState, useEffect, Fragment } from "react";
import DataTable from "../components/DataTable";
import Paginator from "../components/paginator";
import SectionHeader from "../components/SectionHeader";
import Dialog from "../components/Dialog";
import Modal from "../components/Modal";
import UserData from "../components/UserData";
import { useTranslation } from "react-i18next";
import AutoComplete from "../components/AutoComplete";
import EditForm from "../components/EditForm";
import axios from "axios";
import {
  EyeIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import config from "../config";
import { nationalities } from "../utils/nationalities.js";

const getCountryName = (alpha2Code) => {
  const country = nationalities.find((nat) => nat.alpha_2_code === alpha2Code);
  return country ? country.en_short_name : "";
};
const daysUntilExit = (exitDate) => {
  if (!exitDate) return null;
  const today = new Date();
  const exit = new Date(exitDate);
  const timeDifference = exit - today;
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

function Services() {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState();
  const [session, setSession] = useState("CAFETERIA");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [addPerson, setAddPerson] = useState();

  const [tableBodyList, setTableBodyList] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [peopleData, setPeopleData] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [state, setState] = useState({
    tableBodyList: tableBodyList,
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
  });

  const [tableHeaders, setTableHeaders] = useState([
    { id: "id", label: t("ID") },
    {
      id: "First Name",
      label: t("First Name"),
      component: (data) => <>{data.firstName || ""}</>,
    },
    {
      id: "Last Name",
      label: t("Last Name"),
      component: (data) => <>{data.lastName || ""}</>,
    },
    {
      id: "gender",
      label: t("Gender"),
      component: (data) => <> {t(data.gender) || ""} </>,
    },
    {
      id: "bornOn",
      label: t("Born on"),
      component: (data) => (
        <>{new Date(data.bornOn).toISOString().slice(0, 10) || ""} </>
      ),
    },
    {
      id: "nationality",
      label: t("Nationality"),
      component: (data) => <>{getCountryName(t(data.nationality)) || ""}</>,
    },
    {
      id: "meals",
      label: t("meals"),
      component: (data) => <>{data.meals || 1}</>,
    },
    {
      id: "Last Used Date",
      label: t("Last Used Date"),
      component: (data) => (
        <>
          {data.lastUsedDate
            ? new Date(data.lastUsedDate).toISOString().slice(0, 10)
            : t("Never Used")}
        </>
      ),
    },
    {
      id: "exitDate",
      label: t("Exit Date"),
      component: (data) => {
        const days = daysUntilExit(data.exitDate);
        return (
          <span
            style={{
              color: days <= 7 ? "red" : "black",
              backgroundColor:
                days <= 7 ? "rgba(255, 0, 0, 0.2)" : "transparent",
              padding: "2px 4px",
              borderRadius: "4px",
            }}
          >
            {new Date(data.exitDate).toISOString().slice(0, 10) ||
              "Non disponibile"}
          </span>
        );
      },
    },

    {
      id: "Image",
      label: t("Photo"),
      component: (data, setData) => (
        <img
          className="w-16 h-16 rounded-full"
          // src={data.Image}
          src={
            data.image ? `${config.ipAddress}/file/${data.image}` : "user.png"
          }
          alt="profile"
        />
      ),
    },
    {
      id: "actions",
      label: "",
      component: (data, setData) => (
        <div className="flex space-x-3 !text-right">
          <button
            className=" no-focus"
            title="User Data"
            onClick={(e) => setPeopleData(data)}
          >
            <EyeIcon className="w-5 h-5" />
          </button>

          <button
            className=" no-focus"
            title="Edit User Data"
            onClick={(e) => setEditModalOpen(data)}
          >
            <PencilIcon className="w-5 h-5" />
          </button>

          <button
            className=" no-focus"
            title="Delete"
            onClick={(e) => deleteFromTable(data)}
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      ),
    },
  ]);

  const handleEditCancel = () => {
    setEditModalOpen(false);
  };

  const getServices = () => {
    setLoading(true);
    axios
      .get(`${config.ipAddress}/service`, {
        params: {
          page,
          limit,
          date: new Date().toDateString(),
          service: session,
        },
      }) // Pass searchQuery to API call
      .then((res) => {
        console.log("test", res.data.list);
        setTableBodyList(res.data.list);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const getDayName = () => {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const dayName = dayNames[today.getDay()];

    return dayName;
  };

  const isBetween7to930 = () => {
    const currentTime = new Date();
    // currentTime.setHours(20, 0, 0, 0); manually set 8pm just to test
    const currentHour = currentTime.getHours(); // Get current hour (0-23)
    const currentMinutes = currentTime.getMinutes(); // Get current minutes (0-59)

    // Define the time range (in 24-hour format)
    const startHour = 19; // 7:00 PM
    const endHour = 21; // 9:00 PM
    const endMinutes = 30; // 9:30 PM

    // Check if current time is between 7:00 PM and 9:30 PM
    const isInRange =
      (currentHour === startHour && currentMinutes >= 0) ||
      (currentHour > startHour && currentHour < endHour) ||
      (currentHour === endHour && currentMinutes <= endMinutes);
    if (isInRange) {
      return true;
    } else {
      return false;
    }
  };

  const isBetween5to630 = () => {
    // Get the current time
    const currentTime = new Date();
    const currentHour = currentTime.getHours(); // Get current hour (0-23)
    const currentMinutes = currentTime.getMinutes(); // Get current minutes (0-59)

    // Define the time range
    const startHour = 17; // 5:00 PM (24-hour format)
    const endHour = 18; // 6:00 PM (24-hour format)
    const endMinutes = 30; // 6:30 PM

    // Check if current time is between 5:00 PM and 6:30 PM
    const isInRange =
      (currentHour === startHour && currentMinutes >= 0) ||
      (currentHour > startHour && currentHour < endHour) ||
      (currentHour === endHour && currentMinutes <= endMinutes);

    if (isInRange) {
      return true;
    } else {
      return false;
    }
  };
  const isBetween4to5 = () => {
    // Get the current time
    const currentTime = new Date();
    const currentHour = currentTime.getHours(); // Get current hour (0-23)
    const currentMinutes = currentTime.getMinutes(); // Get current minutes (0-59)

    // Define the time range
    const startHour = 16; // 4:00 PM (24-hour format)
    const endHour = 17; // 5:00 PM (24-hour format)

    // Check if current time is between 4:00 PM and 5:00 PM
    const isInRange =
      (currentHour === startHour && currentMinutes >= 0) ||
      (currentHour === endHour && currentMinutes === 0);

    if (isInRange) {
      return true;
    } else {
      return false;
    }
  };

  const handleTimeTable = () => {
    let day = getDayName();
    let is7to930 = isBetween7to930();
    if (["Wednesday", "Friday"].includes(day) && is7to930) {
      setSession("SHOWERS");
    } else if (
      [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ].includes(day) &&
      isBetween5to630()
    ) {
      setSession("CAFETERIA");
    } else if (isBetween4to5()) {
      setSession("TAKEAWAY_PACKAGE");
    }
  };

  useEffect(() => {
    getServices();
  }, [limit, page, addPerson, session]);

  useEffect(() => {
    handleTimeTable();
  }, []);
  function deleteFromTable(data) {
    axios
      .delete(`${config.ipAddress}/service`, {
        params: { id: data.id },
      })
      .then((res) => {
        getServices();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="mt-10">
      <SectionHeader
        title={t("Services")}
        mainPage={"Services"}
        mainPageLink={"/services"}
        tools={() => {
          return (
            <div className="inline-flex  items-center gap-3">
              <select
                id="maritalStatus"
                name="maritalStatus"
                value={session}
                onChange={(e) => setSession(e.target.value)}
                autoComplete="Marital status"
                className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
              >
                <option value={"CAFETERIA"}>{t("Cafeteria")}</option>
                <option value={"TAKEAWAY_PACKAGE"}>
                  {t("Takeaway Package")}
                </option>
                <option value={"SHOWERS"}>{t("Shower")}</option>
                <option value={"COVERS"}>{t("Covers")}</option>
                <option value={"MEDICINES"}>{t("Medicines")}</option>
              </select>

              {/* <button type="button" class="rounded-md  bg-indigo-600 px-3 py-2 text-sm font-semibold text-white whitespace-nowrap shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"> Start Session</button> */}
            </div>
          );
        }}
      />
      <Dialog
        onFalse={(e) =>
          setState((prevState) => ({
            ...prevState,
            dialogInfo: { isOpened: false, text: "" },
          }))
        }
        onTrue={(e) => deleteFromTable(e)}
        dialogInfo={state.dialogInfo}
      />
      <Modal isModalOpen={!!peopleData} setModalOpen={setPeopleData}>
        <div className="bg-white text-left text-black w-[600px] md:w-[700px] rounded-lg my-8 mx-auto flex flex-col">
          <div className="border-b flex justify-between items-center px-6 py-4">
            <h4 className="text-base text-gray-900 font-semibold">
              {t("User Data")}
            </h4>
            <XMarkIcon
              onClick={() => setPeopleData(false)}
              className="w-5 h-5 text-gray-600 cursor-pointer"
            />
          </div>

          <div className="flex justify-center items-center">
            <UserData {...peopleData} />
          </div>
        </div>
      </Modal>
      <Modal isModalOpen={!!editModalOpen} setModalOpen={setEditModalOpen}>
        <div className="bg-white text-left text-black w-[900px] md:w-[950px] rounded-lg my-8 mx-auto flex flex-col">
          <div className="border-b flex justify-between items-center px-6 py-4">
            <h4 className="text-base text-gray-900 font-semibold">
              {t("Edit User Data")}
            </h4>
            <XMarkIcon
              onClick={() => setEditModalOpen(false)}
              className="w-5 h-5 text-gray-600 cursor-pointer"
            />
          </div>

          <div className="flex justify-center items-center">
            <EditForm
              {...editModalOpen}
              onCancel={handleEditCancel}
              setEditModalOpen={setEditModalOpen}
            />
          </div>
        </div>
      </Modal>

      <AutoComplete activeSession={session} setAddPerson={setAddPerson} />
      <DataTable
        isLoading={loading}
        tableHeadersData={tableHeaders}
        setTableHeadersData={setTableHeaders}
        tableBodyData={tableBodyList || []}
        renderPaginator={() => (
          <>
            <Paginator
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              total={count}
            />
          </>
        )}
      />
    </div>
  );
}

export default Services;
