// import React, { useState, useEffect, Fragment } from "react";
// import { useTranslation } from "react-i18next"; // Import useTranslation hook
// import { useNavigate } from 'react-router-dom';

// const DataTable = ({
//   tableHeadersData,
//   tableBodyData,
//   reference,
//   onRowClick,
//   rowClickEnabled,
//   isLoading,
//   renderPaginator,
//   renderPrintView, // New prop for printing view
// }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();  // For navigation

//   const [tableHeaders, setTableHeaders] = useState(tableHeadersData);
//   const [tableBody, setTableBody] = useState(tableBodyData);
//   const [selectedCards, setSelectedCards] = useState([]); // Track selected cards

//   useEffect(() => {
//     setTableBody(tableBodyData);
//   }, [tableBodyData]);

//   useEffect(() => {
//     setTableHeaders(tableHeadersData);
//   }, [tableHeadersData]);

//   function getColumnComponent(id, row) {
//     const col = tableHeaders.filter((x) => x.id === id.id)[0];
//     return (
//       col && (col.component ? col.component(row, setTableBody) : row[id?.id])
//     );
//   }

//     // Function to handle card selection
//     const handleSelectCard = (row) => {
//       setSelectedCards((prevSelected) =>
//         prevSelected.includes(row)
//           ? prevSelected.filter((item) => item !== row)
//           : [...prevSelected, row]
//       );
//     };
//     // Handle printing and navigation
//   const handlePrintView = () => {
//     if (selectedCards.length > 0) {
//       navigate('/print-view', { state: { selectedUsers: selectedCards } });
//     }
//   };

//   return (
//     <div className="inline-block min-w-full py-2  align-middle sm:px-6 lg:px-8 ">
//       <div className="overflow-hidden shadow ring-1  ring-black ring-opacity-5 sm:rounded-lg">
//         <table
//           className="min-w-full divide-y  divide-gray-300 "
//           id="data-table"
//         >
//           <thead className="bg-gray-50 w-full ">
//             <tr>
//               {/* Add a column for checkboxes */}
//               <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
//                 <input
//                   type="checkbox"
//                   onChange={(e) => {
//                     if (e.target.checked) {
//                       setSelectedCards(tableBody); // Select all
//                     } else {
//                       setSelectedCards([]); // Deselect all
//                     }
//                   }}
//                   checked={selectedCards.length === tableBody.length}
//                 />
//               </th>
//               {tableHeaders &&
//                 tableHeaders.map((header, index) => (
//                   <th
//                     scope="col"
//                     className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
//                     key={index}
//                   >
//                     {t(header.label)}
//                   </th>
//                 ))}
//             </tr>
//           </thead>
//           <tbody className="divide-y divide-gray-200 bg-white ">
//             {!isLoading ? (
//               tableBody &&
//               tableBody.map((row, index) => (
//                 <tr key={index}>
//                   {/* Checkbox for each row */}
//                   <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
//                     <input
//                       type="checkbox"
//                       onChange={() => handleSelectCard(row)}
//                       checked={selectedCards.includes(row)}
//                     />
//                   </td>
//                   {tableHeaders &&
//                     tableHeaders.map((col, index) => (
//                       <Fragment key={index}>
//                         <td
//                           key={index}
//                           className={`whitespace-nowrap px-3 py-2 text-sm text-gray-500 ${
//                             rowClickEnabled ? "cursor-pointer" : ""
//                           }`}
//                           onClick={rowClickEnabled ? () => onRowClick(row) : null}
//                         >
//                           {getColumnComponent(col, row)}
//                         </td>
//                       </Fragment>
//                     ))}
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan={tableHeaders.length + 1} className="text-center">
//                   Loading...
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//         {/* Button to trigger print view */}
//         {/* <button
//           onClick={() => renderPrintView(selectedCards)}
//           className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
//         >
//           Print Selected Cards
//         </button> */}

//       </div>
//       {renderPaginator?.()}
//       <button
//         onClick={handlePrintView}  // Use navigation for print view
//         className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
//       >
//         Print Selected Cards
//       </button>
//     </div>
//   );
// };

// export default DataTable;

import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

const DataTable = ({
  tableHeadersData,
  tableBodyData,
  onRowClick,
  rowClickEnabled,
  isLoading,
  renderPaginator,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  const [tableHeaders, setTableHeaders] = useState(tableHeadersData);
  const [tableBody, setTableBody] = useState(tableBodyData);
  const [selectedCards, setSelectedCards] = useState([]);

  useEffect(() => {
    setTableBody(tableBodyData);
  }, [tableBodyData]);

  useEffect(() => {
    setTableHeaders(tableHeadersData);
  }, [tableHeadersData]);

  function getColumnComponent(id, row) {
    const col = tableHeaders.filter((x) => x.id === id.id)[0];
    return (
      col && (col.component ? col.component(row, setTableBody) : row[id?.id])
    );
  }

  const handleSelectCard = (row) => {
    setSelectedCards((prevSelected) =>
      prevSelected.includes(row)
        ? prevSelected.filter((item) => item !== row)
        : [...prevSelected, row]
    );
  };

  const handlePrintView = () => {
    if (selectedCards.length > 0) {
      navigate("/print-view", { state: { selectedUsers: selectedCards } });
    }
  };

  // Check if on the "viewPeople" page
  const isViewPeoplePage = location.pathname === "/viewPeople";

  return (
    <div className="inline-block min-w-full py-2 align-middle px-2">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ">
        <table className="min-w-full divide-y divide-gray-300" id="data-table">
          <thead className="bg-gray-50 w-full">
            <tr>
              {/* Conditionally render the checkbox column */}
              {isViewPeoplePage && (
                <th
                  scope="col"
                  className="py-3.5 pl-4 text-left text-sm font-semibold text-gray-900"
                >
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCards(tableBody);
                      } else {
                        setSelectedCards([]);
                      }
                    }}
                    checked={selectedCards.length === tableBody.length}
                  />
                </th>
              )}
              {tableHeaders.map((header, index) => (
                <th
                  scope="col"
                  className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                  key={index}
                >
                  {t(header.label)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {!isLoading ? (
              tableBody.map((row, index) => (
                <tr key={index}>
                  {isViewPeoplePage && (
                    <td className="whitespace-nowrap py-2 px-4 text-sm text-gray-500">
                      <input
                        type="checkbox"
                        onChange={() => handleSelectCard(row)}
                        checked={selectedCards.includes(row)}
                      />
                    </td>
                  )}
                  {tableHeaders.map((col, index) => (
                    <Fragment key={index}>
                      <td
                        className={`whitespace-nowrap px-2 py-2 text-sm text-gray-500 ${rowClickEnabled ? "cursor-pointer" : ""}`}
                        onClick={rowClickEnabled ? () => onRowClick(row) : null}
                      >
                        {getColumnComponent(col, row)}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={tableHeaders.length + (isViewPeoplePage ? 1 : 0)}
                  className="text-center"
                >
                  Loading...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {renderPaginator?.()}
      {/* Conditionally render the print button */}
      {isViewPeoplePage && (
        <button
          onClick={handlePrintView}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
        >
          {t("Print Selected Cards")}
        </button>
      )}
    </div>
  );
};

export default DataTable;
