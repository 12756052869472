import React from 'react';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { nationalities } from "../utils/nationalities";

// Funzione per ottenere il nome del paese in base alla lingua corrente
const getCountryName = (alpha2Code, language) => {
  const country = nationalities.find((nat) => nat.alpha_2_code === alpha2Code);
  if (!country) return ""; // Se il paese non viene trovato, restituisce una stringa vuota.
  
  // Restituisci il nome della nazionalità in base alla lingua attuale.
  return language === 'it' ? country.it_short_name : country.en_short_name;
};

function BarChart({ barchartStats }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language; // Ottieni la lingua corrente

  // Converti i codici delle nazionalità in nomi dei paesi
  const countryNames = Object.keys(barchartStats.nationalities || {}).map((code) => getCountryName(code, language));

  const options = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: countryNames // Utilizza i nomi dei paesi tradotti
    }
  };

  const series = [{
    data: Object.values(barchartStats.nationalities || {})
  }];

  return (
    <div>
      {t("Services by countries")}
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}

export default BarChart;
